import MainLayout from "../layouts/MainLayout";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { getFavourites } from "../services/bidiService";
import Book from "../components/Book";
import NoContent from "../components/NoContent";
import { Button } from "@mui/material";
import DeleteBookFromFavourite from "../components/modals/DeleteBookFromFavourite";
import { customText } from "../customization/customText";

function MyFavourites() {
  const authContext = useContext(AuthContext);

  const institution = authContext.authData.institution;
  const user = authContext.authData.user;
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteBook, setShowDeleteBook] = useState(false);
  const [FavouriteToDelete, setFavouriteToDelete] = useState();

  useEffect(() => {
    getFavourites(authContext.authData.bidi_access_token, user.id).then((response) => {
      setFavourites(response.data.user_collections.favourites);
      setLoading(false);
    });
  }, []);

  const deleteFavouriteBook = (id) => {
    setFavouriteToDelete(id);
    setShowDeleteBook(true);
  }
  const onDeleteFavouriteBook = (id) => {
    setFavourites(favourites.filter(f => f.id !== id));
    setShowDeleteBook(false);
  }

  return (
    <MainLayout loading={loading} currentPageKey="Mis favoritos" currentPageName={ customText('breadcrumbs.favorites') }>
      <div className="py-8">
        {(favourites.length === 0) ? <NoContent>
          En esta sección visualizarás todos los libros que seleccionaste como favoritos. Recordá que los libros expirados los encontrarás en Historial.
          <Button variant="contained" fullWidth href="/catalogo">Ver catálogo</Button>
        </NoContent> :
          <div>
            <DeleteBookFromFavourite userId={user.id} onDelete={onDeleteFavouriteBook} idFav={FavouriteToDelete} show={showDeleteBook} onClose={() => setShowDeleteBook(false)} />

            <div className="flex items-center">
              <div className="flex-grow">
                <h2 className="text-2xl font-bold">{ customText('favorites.title') }</h2>
              </div>
            </div>
            <div className="py-4">
              <div className="flex flex-wrap gap-6">
                {favourites.map((favourite, index) => {
                  return (
                    <Book key={index} book={favourite.book} menu={[{ id: favourite.id, name: 'Eliminar', onClick: deleteFavouriteBook }]} />);
                })}
              </div>
            </div>
          </div>}
      </div>
    </MainLayout>
  );
}

export default MyFavourites;