import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';
const Topbar = () => {
    const authContext = useContext(AuthContext);
    const user = authContext.authData.user;
    const institution = authContext.authData.institution;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        window.location.href = '/logout';
    };
    const login = () => {
        window.location.href = `/account/${institution.name}/login?r=${window.location.pathname}`
    };
    return (
        <div id="topbar" className="flex flex-shrink-0 h-[0.35rem] lg:h-8 justify-center" style={{ "backgroundColor": authContext.authData.institution.primaryColor }}>
            <div className="lg:flex flex-grow justify-end  max-w-5xl px-6 items-center hidden " >
                {user ? <>
                    <Button style={{ "color": "#FFFFFF" }} title="Menú de usuario" size="small" startIcon={<AccountCircleIcon />} onClick={handleClick} >{user.name || user.email}</Button>
                    <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} >
                        <MenuItem onClick={() => logout()}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Salir
                        </MenuItem>
                    </Menu>
                </> :
                    <Button style={{ "color": "#FFFFFF" }} title="Ir al login" size="small" startIcon={<LogoutIcon />} onClick={() => login()} >Ingresar</Button>
                }
            </div>
        </div>
    );

}

export default Topbar;