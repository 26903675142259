import { Button, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { updateUserProfile } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";

const AcceptConditionsModal = ({ onClose, ...props }) => {
  const authContext = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    setLoading(true);
    updateUserProfile(authContext.authData.bidi_access_token, { "accepts_conditions": true }).then(() => {
      onClose();
    }).catch(() => setLoading(false));
  };

  return <SimpleModal {...props} show={true}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <div className="flex flex-col gap-6">
        <div>
          Autorizo a Biblioteca Digital a notificarme la disponibilidad de libros utilizando mi Email.
        </div>
        <div className="flex gap-4">
          <Button fullWidth variant="contained" onClick={() => handleOnClose()}>Aceptar</Button>
        </div>
      </div>
    }
  </SimpleModal>
};

export default AcceptConditionsModal;