import { getInstitutions } from './../services/bidiService';
import { useState, useEffect } from 'react';
import PreloginLayout from '../layouts/PreloginLayout';
import { useNavigate } from "react-router-dom";
import SimpleModal from '../components/modals/SimpleModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function Institutions() {
  const navigate = useNavigate();

  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const[word,setWord]=useState("");

  useEffect(() => {
    getInstitutions().then((response) => {
      setInstitutions(response.data);
      setLoading(false);
    });
  }, [])

  const handleInstitutionOnClick = (event, institution) => {
    event.preventDefault();

    if (institution.login_methods.login_url) {
      window.location = institution.login_methods.login_url;
    } else if (institution.login_methods.native) {
      navigate(`/account/${institution.name}/login`);
    } else {
      //The institution doesn't support native loginsssss
      setShowModal(true);
    }
  }

  const onCloseModal = (event) => {
    setShowModal(false);
  };
  
  return (
    <PreloginLayout loading={loading}>
      <SimpleModal show={showModal} onClose={onCloseModal} title="La institución no soporta el login nativo" description="Debes ingresar desde la página de tu institución"></SimpleModal>

      <div  className="flex justify-center py-4 ">
        <TextField onChange={(e)=>setWord(e.target.value)} label="Buscar Institucion" variant="standard" />
        <div className="p-4"><SearchIcon fontSize="medium" /></div>
      </div>

      <p>Para acceder al ítem solicitado de la Biblioteca Digital, identifícate presionando sobre el logo de tu institución.</p>

      <div className="p-10">
        {institutions.filter(i => i.show_in_front && i.name.startsWith(word)).map(i => (
          <div className="p-5 inline-block" key={i.name}>
            <a href={`/account/${i.name}/login`} onClick={e => handleInstitutionOnClick(e, i)} title={`Ir a la biblioteca de ${i.name}`}>
              <LazyLoadImage
                className="h-16"
                alt={`Logo de la biblioteca ${i.name}`}
                src={i.logo} onError={(event) => event.target.style.display = 'none'} />
            </a>
          </div>))}
      </div>
    </PreloginLayout>
  );
}

export default Institutions;
