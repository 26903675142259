import { useState, useEffect, useContext, Fragment } from "react";
import PreloginLayout from "../layouts/PreloginLayout";
import { getInstitutions, getRegisterExtraFields, login, register } from "../services/bidiService";
import { TextField, Button, Stack, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import SimpleModal from '../components/modals/SimpleModal';
import PasswordField from "../components/PasswordField";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';


function Register() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [institution, setInstitution] = useState([]);
  const [user, setUser] = useState({});
  const [extrafields, setExtrafields] = useState([]);
  const [extrafieldsValues, setExtrafieldsValues] = useState({});

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [modalMessage, setModalMessage] = useState("");

  const { institution_name } = useParams();

  useEffect(() => {
    getInstitutions(institution_name).then((response) => {
      const institution = response.data.find(i => i.name === institution_name);

      if (!institution) {
        navigate('/notfound');
      }

      if (!institution.supports_native_register) {
        setModalMessage('Para registrarte debes ponerte en contacto con tu institución.');
      }

      if (!institution.show_in_front) {
        setModalMessage('Esta biblioteca se encuentra deshabilitada.');
      }

      setInstitution(institution);

      getRegisterExtraFields(institution.name).then(response => {
        setExtrafields(response.data);
        setLoading(false);
      });

    });
  }, [institution_name, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
    setLoading(true);

    if (event.target.password.value !== event.target.repeat_password.value) {
      setErrors({ password: "Las contraseñas no coinciden" });
      setLoading(false);
      return;
    }

    register(institution_name, user, extrafieldsValues).then(response => {
      const commands = response.data.commands;
      if (commands && commands.find(c => c.name === "Autologin")) {
        login(user.external_id, user.password, institution_name, 'native').then(response2 => {
          authContext.login(response2.data);
        });
        return;
      }

      if (response.data.email_to_confirm) {
        window.location = `/account/${institution_name}/login?code=2`;
      } else {
        window.location = `/account/${institution_name}/login?code=3`;
      }
    }).catch(error => {
      setErrors(error.response.data.fieldMessages);
      setLoading(false);
    });
  };

  const onCloseModal = (event) => {
    window.location = "/institutions";
  };

  return (
    <PreloginLayout loading={loading}>
      {!loading &&
        <LocalizationProvider dateAdapter={DateAdapter}>
          <SimpleModal show={modalMessage} onClose={onCloseModal} title={modalMessage} description=""></SimpleModal>

          <div className="p-2 text-left max-w-min">
            <div className="flex justify-center">
              <img className="h-16 mb-6" alt={`Logo de la biblioteca ${institution.name}`} title="Logo de la biblioteca" src={institution.logo} />
            </div>

            <div className="text-sm mb-4" dangerouslySetInnerHTML={{ __html: institution.native_login_config?.register_welcome_text }}></div>

            <Stack spacing={3} component="form" onSubmit={handleSubmit}>

              {/* external_id or email */}
              <TextField
                id="external_id"
                label={institution.external_id_registration_description}
                name="external_id"
                type={!institution.username_must_be_user ? 'text' : 'email'}
                value={user.external_id}
                error={!!errors.external_id}
                helperText={errors.external_id}
                onChange={(event) => { setUser((!institution.username_must_be_user) ? { ...user, external_id: event.target.value } : { ...user, external_id: event.target.value, email: event.target.value }) }}
                required
                variant="standard" />

              {/* membership_number */}
              {institution.is_membership_number_show &&
                <TextField
                  id="membership_number"
                  label="Número de socio"
                  name="membership_number"
                  onChange={(event) => setUser({ ...user, membership_number: event.target.value })}
                  required={institution.native_login_config.is_membership_number_required}
                  variant="standard" />
              }

              {/* name */}
              <TextField
                id="name"
                label="Nombre"
                name="name"
                value={user.name}
                error={!!errors.name}
                helperText={errors.name}
                onChange={(event) => setUser({ ...user, name: event.target.value })}
                required={institution.native_login_config.is_name_required}
                variant="standard" />

              {/* lastname */}
              <TextField
                id="lastname"
                label="Apellido"
                name="lastname"
                value={user.lastname}
                error={!!errors.lastname}
                helperText={errors.lastname}
                onChange={(event) => setUser({ ...user, lastname: event.target.value })}
                required={institution.native_login_config.is_lastname_required}
                variant="standard" />

              {/* email */}
              {!institution.username_must_be_user &&
                <TextField
                  id="email"
                  label="Email"
                  name="email"
                  value={user.email}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={(event) => setUser({ ...user, email: event.target.value })}
                  required={institution.native_login_config.is_email_required}
                  variant="standard" />
              }

              {/* password */}
              <PasswordField
                id="password"
                label="Contraseña"
                name="password"
                onChange={(event) => setUser({ ...user, password: event.target.value })}
                onClickShowPassword={(show) => setShowPassword(show)}
                required />

              <TextField
                id="repeat_password"
                label="Repetir contraseña"
                name="repeat_password"
                required
                error={!!errors.password}
                helperText={errors.password}
                type={showPassword ? 'text' : 'password'}
                variant="standard" />

              {/* extrafields */}
              {extrafields.map((e, index) => {

                if (e.field_input === 'Select') {
                  return <FormControl key={index} variant="standard">
                    <InputLabel id={`${e.field_name}-label`} required={!!e.is_required} >{e.title}</InputLabel>
                    <Select
                      labelId={`${e.field_name}-label`}
                      id={e.field_name}
                      label={e.placeholder}
                      defaultValue={extrafieldsValues[e.field_name]}
                      onChange={event => { setExtrafieldsValues({ ...extrafieldsValues, [e.field_name]: event.target.value }); }}
                      required={!!e.is_required}
                    >
                      {JSON.parse(e.config).map((option, index2) => <MenuItem key={index2} value={option}>{option}</MenuItem>)}
                    </Select>
                  </FormControl>
                } else if (e.field_input_type === 'text') {
                  return <TextField
                    key={index}
                    id={e.field_name}
                    label={e.placeholder}
                    name={e.field_name}
                    value={extrafieldsValues[e.field_name]}
                    onChange={event => { setExtrafieldsValues({ ...extrafieldsValues, [e.field_name]: event.target.value }); }}
                    required={!!e.is_required} minLength={e.min_length} maxLength={e.max_length}
                    variant="standard" />
                } else if (e.field_input_type === 'date') {
                  return <Fragment key={index}>
                    <DatePicker
                      label={e.placeholder}
                      name={e.field_name}
                      required={!!e.is_required}
                      inputFormat="DD/MM/yyyy"
                      value={extrafieldsValues[e.field_name]}
                      onChange={newValue => { setExtrafieldsValues({ ...extrafieldsValues, [e.field_name]: newValue }); }}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    /></Fragment>
                }
                return <></>
              })}

              <div className="text-sm text-red-600 mb-4">Todos los campos marcados con un asterisco (*) son obligatorios.</div>

              <Stack spacing={2} direction="row">
                <div className="min-w-[8.5rem]">
                  {
                    <Button href={`/account/${institution.name}/login`}>Ingresar</Button>
                  }
                </div>
                <Button variant="contained" type="submit">Registrarme</Button>
              </Stack>
            </Stack>

          </div>
        </LocalizationProvider>
      }
    </PreloginLayout >
  );
}

export default Register;
