import '@splidejs/splide/dist/css/splide.min.css';
import { Button } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Card from './Card';
import Link from '@mui/material/Link';

const Collection = ({ data }) => {

  const options = {
    type: 'slide', perPage: 6, perMove: 1, pagination: false, gap: '0.2rem', slideFocus: false,
    breakpoints: {
      980: { perPage: 5 },
      720: { perPage: 4 },
      600: { perPage: 3 },
      480: { perPage: 2 }
    }
  };

  return (
    <>
      {/* Header */}
      <div className="flex items-center">
        <div className="flex-grow">
          <h2 className="text-2xl font-bold">{data.title}</h2>
        </div>
        <div className="mx-4">
          {data.link &&
            <Link className="whitespace-nowrap " underline="hover" href={data.link}>Ver más</Link>
          }
        </div>
      </div>

      {/* Carrousel */}
      <style>{`.splide__arrow[disabled] { display: none; }`}</style>
      <Splide
        options={options}
      >
        {data.items.map((i, index) => {
          return (
            <SplideSlide key={index}>
              <Card id={i.external_id} book={i.book} />
            </SplideSlide>);
        })}
      </Splide>
    </>
  );
}

export default Collection;