import { Button, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { deleteReservation } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";

const CancelReservationModal = ({ reservation_id, onCancel, ...props }) => {
  const authContext = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    deleteReservation(authContext.authData.customer_access_token, reservation_id).then(response => {
      onCancel(reservation_id);
    }).catch(() => setLoading(false));
  };

  return <SimpleModal title="¿Deseas salir de la lista de espera?" {...props}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <div className="flex justify-center gap-2 pt-4">
        <Button fullWidth variant="outlined" onClick={() => props.onClose()}>No, gracias</Button>
        <Button fullWidth variant="contained" onClick={handleSubmit}>Si, salir</Button>
      </div>}
  </SimpleModal>
};

export default CancelReservationModal;