import { Button, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { deleteFavourite } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";


const DeleteBookFromFavourite = ({ userId, idFav, onDelete, ...props }) => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    deleteFavourite(authContext.authData.bidi_access_token, userId, idFav).then(response => {
      setLoading(false);
      onDelete(idFav);
    }).catch(() => setLoading(false));
  };

  return <SimpleModal title="¿Deseas eliminar este libro de Favoritos?" {...props}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <div className="flex justify-center gap-2 pt-4">
        <Button fullWidth variant="outlined" onClick={() => props.onClose()}>No, gracias</Button>
        <Button fullWidth variant="contained" onClick={handleSubmit}>Si, eliminar</Button>
      </div>}
  </SimpleModal>
};

export default DeleteBookFromFavourite;