import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import PasswordField from "../components/PasswordField";
import { AuthContext } from "../contexts/AuthContext";
import { customText } from "../customization/customText";
import { updateOrRegisterCustomer } from "../services/bidiService";

const ReadingKey = ({ helperText, onSuccess }) => {
  const authContext = useContext(AuthContext);
  const user = authContext.authData.user;

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const handleChangePasswordAppSubmit = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    if (event.target.password.value !== event.target.repeat_password.value) {
      setError("Las contraseñas no coinciden");
      setLoading(false);
      return;
    }

    updateOrRegisterCustomer(
      authContext.authData.bidi_access_token,
      user.email,
      event.target.password.value
    )
      .then((response) => {
        authContext.updateCustomerAccessToken(response.data.id_token);
        authContext.updateUser({ alreadyHaveReader: true });

        event.target.password.value = "";
        event.target.repeat_password.value = "";
        setError("");
        setLoading(false);
        onSuccess(customText("profile.password_changed"));
      })
      .catch((error) => setLoading(false));
  };

  return (
    <>
      <div className="flex-grow">
        <h2 className="text-2xl font-bold">{customText("menu.profile")}</h2>
        <br />
      </div>
      <Typography sx={{ mb: 2.5 }} className="font-bold">
        {customText("profile.reading_key_subtitle")}
      </Typography>
      <div className="mb-4">{customText("profile.reading_key_text1")}</div>
      <Stack
        spacing={3}
        component="form"
        onSubmit={handleChangePasswordAppSubmit}
      >
        <TextField
          id="email"
          label="Email"
          name="email"
          defaultValue={user.email}
          disabled
          type="email"
          variant="standard"
        />
        <PasswordField
          id="password"
          label="Nueva contraseña"
          name="password"
          onClickShowPassword={(show) => setShowPassword(show)}
          required
        />
        <TextField
          id="repeat_password"
          error={!!error}
          helperText={error}
          label="Repetir contraseña"
          name="repeat_password"
          required
          type={showPassword ? "text" : "password"}
          variant="standard"
        />

        <div className="text-center">
          <LoadingButton variant="contained" type="submit" loading={loading}>
            Enviar
          </LoadingButton>
        </div>
      </Stack>
      {helperText && (
        <div className="mt-6 flex">
          <HelpIcon className="mr-2 text-[#258BD1]" />
          <div>{helperText}</div>
        </div>
      )}
    </>
  );
};

export default ReadingKey;
