import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';


const SearchBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("q") || "");

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location = (query.length === 0) ? '/catalogo' : `/busqueda?q=${query}`;
  };

  return (
    <Paper id="buscador"
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
      onSubmit={handleSubmit}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="¿Qué querés leer hoy?"
        inputProps={{ 'aria-label': '¿Qué querés leer hoy?' }}
        onChange={(e) => setQuery(e.target.value)}
        value={query}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
};

export default SearchBar;