import { Settings } from "@mui/icons-material";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { customText } from "../customization/customText";

const Navbar = ({ currentPageKey }) => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  return (
    <div className="border-b pt-1 flex ">
      <BottomNavigation
        sx={{ height: "100%!important" }}
        className="w-full flex flex-wrap "
        showLabels
        value={currentPageKey}
      >
        {institution.showNavigationBar && (
          <BottomNavigationAction
            value="Catálogo"
            label="Catálogo"
            href="/catalogo"
            icon={<LibraryBooksOutlinedIcon />}
          />
        )}
        {user && (
          <BottomNavigationAction
            value="Mis libros"
            label="Mis libros"
            href="/mislibros"
            icon={<BookOutlinedIcon />}
          />
        )}
        {user && (
          <BottomNavigationAction
            value="Mis reservas"
            label="Reservas"
            href="/misreservas"
            icon={<TodayOutlinedIcon />}
          />
        )}
        {user && (
          <BottomNavigationAction
            value="Mis favoritos"
            label="Favoritos"
            href="/misfavoritos"
            icon={<FavoriteBorderOutlinedIcon />}
          />
        )}
        {user && (
          <BottomNavigationAction
            value="Historial"
            label="Historial"
            href="/historial"
            icon={<HistoryOutlinedIcon />}
          />
        )}
        {user && (
          <BottomNavigationAction
            value="Configuración"
            // label="Configuración"
            label={customText("menu.profile")}
            href="/perfil"
            icon={<Settings />}
          />
        )}
        {institution.showNavigationBar && (
          <BottomNavigationAction
            value="Ayuda"
            label="Ayuda"
            href="/ayuda"
            icon={<QuestionMarkIcon />}
          />
        )}
      </BottomNavigation>
    </div>
  );
};

export default Navbar;
