import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";

const Tutorial = () => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;

  return <MainLayout currentPageName="Tutorial">
    <div className="py-4">
      <iframe width="100%" height="520px" src={institution.tutorialVideoUrl || "https://www.youtube.com/embed/GZbsAj7yJCg"} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div></MainLayout>;
};

export default Tutorial;