import NoContentImage from "../assets/images/nocontent224x150.png"

const NoContent = ({ children }) => {
  return <div className="flex justify-center">
    <div className="flex justify-center gap-8 flex-wrap max-w-2xl">
      <figure className="flex-shrink-0">
        <img className="h-40" src={NoContentImage} alt="No hay contenido" />
      </figure>
      <div className="flex flex-col gap-4 max-w-sm">
        {children}
      </div>
    </div>
  </div>
};

export default NoContent;