import { Button, CircularProgress, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getInstitution, getInstitutions } from "../services/bidiService";
import { Box } from "@mui/system";

const ErrorLogin = ({ onClose, title, description, children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [institutionName, setInstitutionName] = useState();
    const [institutionUrl, setInstitutionUrl] = useState();
    const [institutionPhone, setInstitutionPhone] = useState(searchParams.get("phone"));
    const [loading, setLoading] = useState(true);
    const totalAttempts = 3;

    useEffect(() => {
        getInstitution(searchParams.get("name")).then((response) => {
            setInstitutionName(response.displayName);
            setLoading(false);
        }).catch(error => {
            alert("No se encontro la institución ");
            window.location = "/institutions"
        });
        setLoading(true);
        getInstitutions().then((response) => {
            const institution = response.data.filter(i => i.name = searchParams.get("name"))[0];

            if (!!institution.login_methods.login_url) {
                setInstitutionUrl(institution.login_methods.login_url);
            } else if (institution.login_methods.native) {
                setInstitutionUrl(`/account/${institution.name}/login`);
            }

            if (!completeAttempts()) {
                window.location = `/account/${searchParams.get("name")}/login`;
            }
            setLoading(false);
        });

    }, [])

    /**
     * Informa si llegó al límite de intentos de login
     */
    const completeAttempts = () => {
        const attempts = searchParams.get("attempts") ? searchParams.get("attempts") : 0;
        
        return attempts >= totalAttempts;
    };

    return (<Box>

        {(loading || !completeAttempts()) ? <Box sx={{ textAlign: 'center' }}><CircularProgress /></Box> :

            <Modal
                aria-labelledby={title}
                aria-describedby={description}
                open={true}
                onClose={onClose}
            >
                <div className="w-11/12 sm:w-full max-w-xl outline-0 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] shadow-md p-6 bg-white">
                    <div className="absolute top-3 right-3">
                        {onClose && <CloseIcon onClick={onClose} />}
                    </div>

                    <div className="pt-3 px-4">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            No se ha podido autenticar.
                        </Typography>
                        {children ? children : <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Hubo un error durante el proceso de autenticación en la biblioteca. Por favor, te pedimos que te contactes con Mesa de Ayuda al {institutionPhone} o vuelvas a intentar más tarde.
                        </Typography>}
                    </div>
                    <br />
                    <Button variant="contained" fullWidth href={institutionUrl}>Continuar en {institutionName} </Button>
                </div>
            </Modal>}
    </Box>
    );
}

export default ErrorLogin;