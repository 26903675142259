import { Button, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { updateUserProfile } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";

const TutorialModal = ({ onClose, ...props }) => {
  const authContext = useContext(AuthContext);
  const user = authContext.authData.user;

  const [loading, setLoading] = useState(false);

  const handleOnClose = (show_tutorial) => {
    setLoading(true);
    updateUserProfile(authContext.authData.bidi_access_token, { "show_tutorial": false }).then(() => {
      onClose(show_tutorial && '/tutorial');  
    }).catch(() => setLoading(false));
  };

  return <SimpleModal onClose={() => handleOnClose(false)} {...props} show={true}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <div className="flex flex-col gap-6">
        <div>
          Bienvenid@ <strong>{user.name || user.email}</strong>. recorre el tutorial para conocer las funcionalidades de <strong>Bidi</strong>, ó, más tarde presionando el link <i>Tutorial</i> que se encuentra al final de la página.
        </div>
        <div className="flex gap-4">
          <Button fullWidth variant="outlined" onClick={() => handleOnClose(false)}>Mas tarde</Button>
          <Button fullWidth variant="contained" onClick={() => handleOnClose(true)}>Comenzar</Button>
        </div>
      </div>
    }
  </SimpleModal>
};

export default TutorialModal;
