
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';
import { useContext, useEffect, useState } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import { AuthContext } from '../contexts/AuthContext';
import { addFavourite, deleteFavourite, getFavourites } from '../services/bidiService';

const FavouriteButton = ({ book, disabledButton, ...props }) => {
  const authContext = useContext(AuthContext);
  const user = authContext.authData.user;
  const [userCollectionId, setUserCollectionId] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    getFavourites(authContext.authData.bidi_access_token, user.id).then((response) => {
      const userCollection = response.data.user_collections.favourites.find(r => r.book?.id === book.id);
      setUserCollectionId(userCollection?.id);
      setIsFavorite(userCollection?.id !== undefined);
      setLoading(false);
    });
  }, []);

  const toggleFavourite = () => {
    setLoading(true);
    if (!isFavorite) {
      addFavourite(authContext.authData.bidi_access_token, user.id, book.catalog_item_id).then((response) => {
        const userCollection = response.data.user_collections.favourites.find(r => r.book?.id === book.id);
        setUserCollectionId(userCollection?.id);
        setIsFavorite(true);
        setLoading(false);
      });
    }
    else {
      deleteFavourite(authContext.authData.bidi_access_token, user.id, userCollectionId).then(() => {
        setUserCollectionId(null);
        setIsFavorite(false);
        setLoading(false);
      });
    }
  };

  return (
    <div className='min-h-max'>
      {loading ?
        (<IconButton className="flex justify-center py-8 " ><CircularProgress size='1.1rem' /></IconButton>) :
        isFavorite ?
          (<IconButton disabled={disabledButton} size='small' color="primary" onClick={() => toggleFavourite()} ><FavoriteSharpIcon /></IconButton>) :
          (<IconButton disabled={disabledButton} size='small' color="primary" onClick={() => toggleFavourite()} ><FavoriteBorderSharpIcon /></IconButton>)
      }
    </div>
  )
}

export default FavouriteButton;