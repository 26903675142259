import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { isWebpSupported } from "react-image-webp/dist/utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AudioBookIcon from './../assets/images/audiobook-rounded-icon.png'
import EbookIcon from './../assets/images/ebook-rounded-icon.png'

const Book = ({ book, has_custom_genres, menu, cucarda, cucardaColor = "#4c4c4c", showCategories = true, children }) => {
  const [webpSupported] = useState(isWebpSupported());

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (callback, id) => {
    setAnchorEl(null);
    if (callback) {
      callback(id);
    }
  };

  if (!book) return <></>;

  return <div className="flex flex-1 bg-[#f7f9f9] min-w-[19rem] max-w-[21rem] p-1 relative overflow-hidden">

    {cucarda && <div className="absolute top-[0.5rem] left-[-2.75rem] px-10 py-1 z-10 -rotate-45 text-center text-sm text-white w-40" style={{ backgroundColor: cucardaColor }}>{cucarda}</div>}

    <div className="flex-shrink-0 m-2">
      <a className="flex flex-1" href={`/libro/${book.id}`}>
        <LazyLoadImage
          className="self-center h-44 w-[8.5rem] bg-no-repeat bg-center bg-cover bg-book-cover"
          src={(webpSupported) ? book.images.webp : book.images.jpeg}
          alt={`Tapa del libro ${book.title}`}
          onError={(event) => event.target.style.display = 'none'} />
        <img className="absolute top-[9.25rem] left-[6.5rem]" alt={book.media_type === 'audio' ? "audiobook" : "ebook"} src={book.media_type === 'audio' ? AudioBookIcon : EbookIcon} />
      </a>
    </div>
    <div className="flex flex-grow p-1">
      <div className="flex flex-col gap-2 flex-grow">

        <div className="flex">
          {/* Title */}
          {book.title && <p className="flex-grow line-clamp-2 mb-2 font-bold">
            {<a href={`/libro/${book.id}`}>{book.title}</a>}
          </p>}

          {/* Menu */}
          {menu && <div>
            <IconButton aria-label="menu" aria-haspopup="true" aria-expanded={anchorEl ? 'true' : undefined} onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu id="menu" anchorEl={anchorEl} open={!!anchorEl} onClose={() => handleMenuClose()} MenuListProps={{ 'aria-labelledby': 'menu' }} style={{ display: 'block' }}>
              {menu.map((m, index) => <MenuItem key={index} onClick={() => handleMenuClose(m.onClick, m.id)}>{m.name}</MenuItem>)}
            </Menu>
          </div>}
        </div>

        {/* Metadata */}
        {book.catalog_title && <a href={`/libro/${book.id}`}><p className=" text-white text-xs uppercase rounded p-1 bg-[#7447C9]" >{book.catalog_title}</p></a>}
        {book.contributors && <a href={`/libro/${book.id}`}><p className="text-xs">{book.contributors.length > 0 && book.contributors[0].name}</p></a>}
        {book.publishers && <a href={`/libro/${book.id}`}><p className="text-xs">Editorial: {book.publishers.length > 0 && book.publishers.map(p => p.name).join(', ')}{book.year && <>, <time>{moment(book.year).year()}</time></>}</p></a>}
        {book.format && <a href={`/libro/${book.id}`}><p className="text-xs">Formato: {book.format.toUpperCase()}</p></a>}
        {showCategories && book.categories && !has_custom_genres && <div>
          <Divider />
          <div id="bookCategories" className="flex flex-wrap gap-1 pt-1">
            {book.categories.length > 0 && book.categories.map((c, index) =>
              <a className="flex flex-1" key={index} href={`catalogo?categories=${c.id}`}>
                <div key={index} className="bg-[#676666] text-white text-xs uppercase rounded p-1">{c.name}</div>
              </a>
            )}
          </div>
        </div>}
        {showCategories && book.custom_genres && !!has_custom_genres && <div>
          <Divider />
          <div id="bookCategories" className="flex flex-wrap gap-1 pt-1">
            {book.custom_genres.length > 0 && book.custom_genres.map((c, index) =>
              <a className="flex flex-1" key={index} href={`catalogo?custom_genres=${c.id}`}>
                <div key={index} className="bg-[#676666] text-white text-xs uppercase rounded p-1">{c.description}</div>
              </a>

            )}
          </div>
        </div>}
        {children && <><Divider />{children}</>}
      </div>
    </div>
  </div>;
};

export default Book;
