import { Button } from "@mui/material";

const NotFound = () => {
  return (<div className="h-full flex items-center justify-center py-8">
    <article>
      <div className="text-center bg-no-repeat bg-center h-[530px] w-[850px] pl-[518px] pr-[118px] pt-[222px] bg-notfound">
        <p className="pb-4">
          Lo sentimos, la página que estás buscando no se encuentra disponible.
        </p>
        <Button variant="contained" href="/">Volver al Inicio</Button>
      </div>
    </article>
  </div>
  );
}

export default NotFound;
