import { Button, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { createReservation, getReservationQueueSize } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";
import Stack from '@mui/material/Stack';

const CreateReservationModal = ({ book_title, catalog_item_id, ...props }) => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  const [loading, setLoading] = useState(true);
  const [queueInfo, setQueueInfo] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    getReservationQueueSize(institution.name, catalog_item_id).then(response => {
      setQueueInfo(response.data);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    createReservation(authContext.authData.customer_access_token, institution.name, catalog_item_id, user.id).then(() => {

      setMessage(`El libro <strong>${book_title}</strong> se ha reservado con éxito. Podrás encontrarlo dentro de <a href="/misreservas" title="Ir a mis reservas">Mis Reservas</a>`);

      setLoading(false);
    }).catch(error => {
      switch (error.response.data.error) {
        case "E_MAX_RESERVATIONS_PER_USER":
          setMessage("Ya alcanzaste el máximo de libros reservados simultáneamente.");
          break;
        case "E_ACTIVE_RESERVE":
          setMessage(`El libro <strong>${book_title}</strong> ya se encuentra dentro de <a href="/misreservas" title="Ir a mis reservas">Mis Reservas</a>`);
          break;
        default:
          setMessage("Momentánemente este libro no se encuentra disponible. Por favor, contáctese con el administrador de la Biblioteca.");
      }

      setLoading(false);
    });
  };

  return <SimpleModal show={true} {...props}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <div>
        {message ? <p dangerouslySetInnerHTML={{ __html: message }}></p> :
          <Stack spacing={2}>
            <p>Estás por reservar el libro <strong>{book_title}</strong></p>
            {queueInfo.queue_size === 0 ? <p>Eres el primero en la lista, el tiempo máximo de espera es de <strong>{queueInfo.max_wait} días</strong></p> :
              <p>Hay <strong>{queueInfo.queue_size} personas</strong> en la lista, el tiempo máximo de espera es de <strong>{queueInfo.max_wait} días</strong></p>}
            <p>Te notificaremos por correo cuando el libro esté disponible.</p>
            <div className="flex justify-center gap-2">
              <Button fullWidth variant="outlined" onClick={() => props.onClose()}>Cancelar</Button>
              <Button fullWidth variant="contained" onClick={handleSubmit}>Continuar</Button>
            </div>
          </Stack>}
      </div>}
  </SimpleModal>
};

export default CreateReservationModal;