import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { loginByToken } from "../services/bidiService";

function LoginByToken() {
  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const redirect_url = searchParams.get("r") || "/";

  useEffect(() => {
    const token = searchParams.get("token") || searchParams.get("oauth_token");

    loginByToken(token).then(response => {
      if(!response.data.institution.showInFront) {
        alert("Esta biblioteca se encuentra deshabilitada.");
        return;
      }

      authContext.login(response.data, redirect_url);
    }).catch(error => alert("Token inválido"));
  }, []);

  return null;
}

export default LoginByToken;
