import { Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const SimpleModal = ({ show, onClose, title, description, children }) => {
  return (
    <Modal
      aria-labelledby={title}
      aria-describedby={description}
      open={!!show}
      onClose={onClose}
    >
      <div className="w-11/12 sm:w-full max-w-xl outline-0 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] shadow-md p-6 bg-white">
        <div className="absolute top-3 right-3">
          { onClose && <CloseIcon onClick={onClose} /> }
        </div>

        <div className="pt-3 px-4">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          { children ? children : <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography> }
        </div>
      </div>
    </Modal>
  );
}

export default SimpleModal;