import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { updateOrRegisterCustomer, updateUserProfile } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";

const DataValidationModal = ({ onClose, ...props }) => {
  const authContext = useContext(AuthContext);
  const user = authContext.authData.user;

  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");


  const handleSubmit = (event) => {
    setEmailError("");
    setLoading(true);

    const userData = {
      "name": event.target.name.value,
      "last_name": event.target.lastname.value,
      "email": event.target.email.value,
      "accepts_conditions": true,
      "data_validated": true
    }

    updateUserProfile(authContext.authData.bidi_access_token, userData).then(() => {
      updateOrRegisterCustomer(authContext.authData.bidi_access_token, userData.email).then((response) => {
        authContext.updateCustomerAccessToken(response.data.id_token);
        authContext.updateUser({
          "name": event.target.name.value,
          "lastName": event.target.lastname.value,
          "email": event.target.email.value,
        });
        onClose(window.location.pathname);
      });
    }).catch(() => {
      setEmailError("El email se encuentra registrado");
      setLoading(false);
    });
  };

  return <SimpleModal {...props} show={true}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <div className="flex flex-col gap-6">
        <div>Hola {user.name}, revisa y actualiza tu información de contacto:</div>

        <Stack spacing={3} component="form" onSubmit={handleSubmit}>
          <TextField fullWidth id="name" label="Nombre" name="name" defaultValue={user.name} required variant="standard" />
          <TextField fullWidth id="lastname" label="Apellido" name="lastname" defaultValue={user.lastName} variant="standard" />
          <TextField fullWidth id="email" label="Email" name="email" type="email" error={!!emailError} helperText={emailError} defaultValue={user.email} required variant="standard" />

          <div className="text-sm">Autorizo a Biblioteca Digital a notificarme la disponibilidad de libros utilizando mi Email.</div>

          <Button fullWidth variant="contained" type="submit">Aceptar</Button>
        </Stack>
      </div>}

  </SimpleModal>
};

export default DataValidationModal;