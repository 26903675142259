import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { useState } from 'react';
import AudioBookIcon from './../assets/images/audiobook-rounded-icon.png'
import EbookIcon from './../assets/images/ebook-rounded-icon.png'


const Card = ({ id, book }) => {
  const [webpSupported] = useState(isWebpSupported());
  return (
    <div className="py-4 max-w-[9rem]">
      <a href={`/libro/${id}`}>
        <div className="relative min-h-[12rem]">
          <LazyLoadImage
            className="self-center w-full mb-2 bg-no-repeat bg-center bg-cover bg-book-cover"
            src={(webpSupported) ? book.images.webp : book.images.jpeg}
            alt={`Tapa del libro ${book.title}`}
            onError={(event) => event.target.style.display = 'none'} />
          <img className="absolute top-[9.5rem] left-[5.75rem]" alt={book.media_type === 'audio' ? "audiobook" : "ebook"} src={book.media_type === 'audio' ? AudioBookIcon : EbookIcon} />
          <p className="line-clamp-2 text-xs uppercase">{book.title}</p>
        </div>
      </a>
    </div>
  )
};

export default Card;