import MainLayout from "../layouts/MainLayout";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { getReservations } from "../services/bidiService";
import NoContent from "../components/NoContent";
import Reservation from "../components/Reservation";
import { Button } from "@mui/material";
import { customText } from "../customization/customText";

function MyReservations() {
  const authContext = useContext(AuthContext);

  const institution = authContext.authData.institution;
  const user = authContext.authData.user;
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReservations(authContext.authData.customer_access_token, institution.name, user.id).then((response) => {
      setReservations(response.data.reservations);
      setLoading(false);
    });
  }, []);

  return (
    <MainLayout loading={loading} currentPageKey="Mis reservas" currentPageName={ customText('breadcrumbs.reservations') }>
      <div className="py-8">
        {(reservations.length === 0) ? <NoContent>
          En esta sección visualizarás todos los libros que reservaste. Cuando el ejemplar esté disponible, te lo informaremos por correo electrónico y lo visualizarás en Mis libros.
          <Button variant="contained" fullWidth href="/catalogo">Ver catálogo</Button>
        </NoContent> :
          <div>
            <div className="flex items-center">
              <div className="flex-grow">
                <h2 className="text-2xl font-bold">Mis reservas</h2>
              </div>
            </div>
            <div className="py-4">
              <div className="flex flex-wrap gap-6">
                {reservations.map((reservation, index) => {
                  return (<Reservation key={index} reservation={reservation} onCancel={(id) => setReservations(reservations.filter(r => r.id !== id))} />);
                })}
              </div>
            </div>
          </div>}
      </div>
    </MainLayout>
  );
}

export default MyReservations;