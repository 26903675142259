import React, { useContext, useState } from 'react';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { AuthContext } from '../contexts/AuthContext';

export default function Breadcrumb({ currentPageName }) {
    const authContext = useContext(AuthContext);
    const institution = authContext.authData.institution;



    return (
        <Breadcrumbs sx={{ fontSize: "0.75rem" }} separator="|" aria-label="breadcrumb">
            {!!institution.breadcrumbsFirstLevelTitle && <Link color="inherit" href={institution.breadcrumbsFirstLevelUrl} >
                {institution.breadcrumbsFirstLevelTitle.toUpperCase()}
            </Link>}
            {!currentPageName?
                <Link color="inherit" underline="none" >
                    BIBLIOTECA DIGITAL
                </Link> :
                <Link color="inherit" href="/" >
                    BIBLIOTECA DIGITAL
                </Link>
            }
            {!!currentPageName &&
                <Link color="inherit" underline="none">{currentPageName.toUpperCase()}</Link>}
        </Breadcrumbs>
    );
}