import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import PreloginLayout from "../layouts/PreloginLayout";

const TermsAndConditionsContent = () => {
    return (
        <div className="text-left">
            <div className="flex items-center">
                <div className="flex-grow">
                    <h2 className="text-2xl font-bold">Términos y condiciones</h2>
                </div>
            </div>
            <br />

            <article >
                <p className="mb-6">SÍRVASE LEER CUIDADOSAMENTE ESTE REGLAMENTO DE USO ANTES DE UTILIZAR EL SITIO www.Bidi.la (en adelante, "Bidi.la"). Al utilizar el Sitio Bidi.la, usted presta consentimiento a estos términos de uso. Si Usted no está de acuerdo con los términos del presente Reglamento de Uso, no podrá hacer uso del Sitio Bidi. Bidi.la podrá modificar el presente Reglamento de Uso en el futuro mediante la publicación de los nuevos términos en el Sitio Bidi.la. Usted reconoce y acepta que si utiliza el Servicio con posterioridad a la publicación de dichas modificaciones, Bidi.la considerará su uso como aceptación de los nuevos términos y condiciones.</p>

                <p className="mb-6"><strong> 1. DEMO</strong><br />
                    Por medio del presente se otorga una licencia gratuita temporal de uso del contenido de Bidi.la, limitándose a la descarga y lectura de documentos sin permiso alguno de reproducción, edición, reventa y/o cualquier uso distinto del permitido bajo los presentes términos y condiciones.</p>
                <p className="mb-6"><strong>2. Usuarios</strong><br />
                    El acceso a y/o uso del sitio Bidi.la atribuye a quien lo realiza la condición de usuario, aceptando, desde ese mismo momento, plenamente y sin reserva alguna, las presentes Condiciones Generales de Uso y Particulares que, dado el caso, complementen, modifiquen o sustituyan las Condiciones Generales, en relación con determinados servicios y contenidos de dicho sitio web.</p>
                <p className="mb-6"><strong>3. Uso de la página web, sus servicios y contenidos</strong><br />
                    3.1 El usuario se compromete a utilizar el sitio web y sus servicios y contenidos sin contravenir la legislación vigente, la buena fe, los usos generalmente aceptados y el orden público. Así mismo, es prohibido, el uso de este sitio web con fines ilícitos o lesivos contra Bidi.la o cualquier tercero, o que, de cualquier forma, puedan causar perjuicio o impedir el normal funcionamiento del sitio.<br/>
                    3.2. Respecto de los contenidos (informaciones, textos, gráficos, archivos de sonido y/o imagen, fotografías, diseños, y en general contenidos ofrecidos o mostrados), queda prohibida:<br />
                    3.2.1. Su reproducción, distribución o modificación, total o parcial a menos que se cuente con la autorización de sus legítimos titulares o resulte legalmente permitido. Su utilización para todo tipo de fines comerciales o publicitarios, distintos de los estrictamente permitidos.<br />
                    3.2.2. No se permiten deep-links, ni enlaces IMG o de imagen, ni frames o marcos, ni iframes, layers o capas u otros métodos de enlaces a contenidos e imágenes de Bidi.la sin la previa autorización expresa de esta. En los casos permitidos, salvo aquellos signos que formen parte del "enlace", la página web en el que se establezca no contendrá ninguna marca, nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a Bidi, salvo autorización expresa de éste.<br />
                    3.2.3. El usuario específicamente acepta no acceder (o intentar acceder) a cualquiera de nuestros Recursos a través de cualquier medio automático, ilícito, o no convencional. Involucrarse en cualquier actividad que perturbe o interfiera con nuestros Recursos, incluyendo los servidores y/o las redes en las que nuestros Recursos están alojados o conectados, está terminantemente prohibido.</p>
                <p className="mb-6"><strong>4. Modificación unilateral</strong><br />
                    Bidi podrá modificar unilateralmente y sin previo aviso, siempre que lo considere oportuno, la estructura, diseños, etc. del sitio web, así como modificar o eliminar, los servicios, los contenidos y las Condiciones Generales y Particulares de acceso y/o uso.</p>
                <p className="mb-6"><strong>5. Seguridad</strong><br />
                    Usted reconoce que el Servicio utiliza tecnología diseñada a los fines de la protección de la seguridad de la información digital. Usted no intentará, ni asistirá o incentivará a otros a, evitar, desactivar, ejercer ingeniería inversa o alterar ninguna de las funciones de seguridad o componentes relacionados con el Servicio, por ningún motivo. Cualquier tipo de violaciones a las funciones de seguridad del Sitio Bidi.la podrán resultar en responsabilidad civil o penal.</p>
                <p className="mb-6"><strong>6. Limitaciones</strong><br />
                    Bidi.la no será responsable por los daños y perjuicios que se produzcan por fallas o malas configuraciones del software instalado en el Dispositivo del Usuario, fallas en la transmisión y defectos del fabricante de software. Usted es el único responsable de lo que suceda en los Dispositivos utilizados para acceder al Contenido Digital, y deberá tomar las medidas de seguridad adecuadas para proteger la información alojada en el mismo, a los fines de evitar eventuales pérdidas o daños.</p>
                <p className="mb-6"><strong>7. Propiedad Intelectual:</strong><br />
                    7.1 Usted reconoce que todo derecho, título e interés (incluyendo derecho de propiedad intelectual) en y para con el contenido de Bidi.la es propiedad exclusiva Bidi.la y/o de terceros proveedores del contenido.<br />
                    7.2 Nada en el presente contrato será interpretado como cesión o renuncia de derechos de propiedad intelectual y/o industrial en favor de Usted. Todo derecho, acto o modalidad no expresamente autorizada por escrito por Bidi.la se entenderá como no autorizado.<br />
                    7.3 Usted no modificará en forma alguna el contenido de Bidi.la ni registrará derechos de propiedad industrial y/o intelectual en relación a dicho contenido y/o a las marcas o logotipos que Bidi.la le proporcione, sean éstos propios o de terceros titulares de derechos.</p>
                <p className="mb-6"><strong>8. Política de Privacidad</strong><br />
                    8.1. El objetivo de la política de privacidad de Bidi.la es cumplir al máximo con la ley Nº 25.326 de protección de los datos personales y la legislación vigente en materia de protección de datos personales en general.<br />
                    8.2. Para cualquier duda al respecto, así como para ejercer su derecho de oposición, rectificación o cancelación de sus datos en nuestras bases de datos deberá identificarse de forma fehaciente y dirigirse por escrito a:<br />
                    8.3. Excepto cuando le sea requerido por ley o autoridad competente, Bidi.la no divulgará la información personal provista por Usted sin su permiso. Bidi.la utilizará la información personal provista por Usted exclusivamente a los fines de comunicarse con Usted.<br />
                    8.4. Al usar el Sitio Bidi.la, Usted autoriza a Bidi.la a almacenar el rastro dejado por su dirección IP con el propósito de administrar los sistemas, mejorar la efectividad del Sitio Bidi.la y realizar estudios estadísticos globales, así como para responder ante requerimientos judiciales, si fuese el caso.<br />
                    8.5. Usted tiene la posibilidad de aceptar o rechazar "cookies" configurando para ello su navegador. En ese caso, es posible que algunas de las funcionalidades del Sitio Bidi.la no funcionen adecuadamente. En ocasiones, en los formularios en los que se recogen datos se solicita la autorización para enviarle novedades sobre el servicio de Bidi.la y/o de terceros, y puntualmente Bidi.la realiza encuestas para conocer el nivel de satisfacción del Usuario. Bidi.la, en su calidad de propietaria y gestora del Sitio Bidi.la, no podrá vender ni alquilar a terceras organizaciones su lista de Usuarios y datos de contacto, [aunque sí es posible que, dentro de las comunicaciones que sostenga directamente con el Usuario, se incorpore información de terceras organizaciones que Bidi.la entienda de interés especial para el Usuario]. En todos los formularios en los que aparezca información personal, se usará al menos el [protocolo SSL] para protegerla mediante cifrado en su transmisión por Internet. Bidi.la asegura que ninguna persona ajena a la prestación del servicio podrá acceder, utilizar o divulgar información personal de sus Usuarios.<br />
                    a) Información Personal Solicitada: Bidi solicitará información de carácter personal a los Usuarios de los Servicios con el objeto de poder proporcionarles el mejor servicio posible a través de su Website Bidi.la y con el fin de hacer posible que los Usuarios puedan acceder a productos adecuados a sus gustos e intereses, así como también para generar bases de datos que Bidi utilizará para distintos fines comerciales. El tipo de Información que se solicitará a los Usuarios de los Servicios incluirá, enunciativa pero no limitativa, su nombre, dirección de correo electrónico (e-mail), país y ciudad de origen, entre otros.<br />
                    b) Privacidad: Bidi utilizará la tecnología más avanzada a su alcance para la protección de la Información proporcionada por los Usuarios de los Servicios. Esta tecnología encripta, codifica y previene la intercepción de la información suministrada por Internet. Bidi podrá contener hipervínculos o hipertextos "links", banners, botones y herramientas de búsqueda en la Web que al ser seleccionados o utilizados por los Usuarios re-direccionen a otros portales o sitios de Internet propiedad de terceros. No obstante que en algunos casos estos sitios o portales de terceros se encuentren enmarcados con la barra de navegación o el "Look & Feel" de Bidi, la Información que el Usuario llegase a proporcionar a través de esos sitios o portales no se encuentra cubierta o contemplada por esta política de privacidad y su manejo o utilización no será responsabilidad de Bidi. Por esta razón, Bidi recomienda a los Usuarios verificar las políticas de privacidad desplegadas o aplicables a esos sitios o portales de terceros.</p>
                <p className="mb-6"><strong>9. Vínculos en el Sitio Bidi.la</strong><br />
                    9.1. El Sitio Bidi.la podrá incluir hipervínculos a otros sitios web que no serán propiedad de Bidi.la ni estarán controlados por ésta. Bidi.la no controla los contenidos, informaciones, políticas de privacidad o las prácticas de los sitios web de terceros ni asume responsabilidad alguna por estos. Dichos enlaces tienen como exclusiva función la de facilitar a los usuarios la búsqueda de información, contenidos y servicios en Internet, sin que en ningún caso pueda considerarse una sugerencia, recomendación o invitación para la visita de los mismos. Bidi no comercializa, ni dirige, ni controla previamente, ni hace propios los contenidos, servicios, informaciones y manifestaciones disponibles en dichos sitios web.<br />
                    9.2. El establecimiento de "enlace" no implica o implicará la existencia de relaciones entre Bidi y el titular de la página web o del portal desde el cual se realice, ni el conocimiento y aceptación de Bidi de los servicios y contenidos ofrecidos en dicho portal.<br />
                    9.3. Bidi.la recomienda a sus Usuarios leer los términos y condiciones y la política de privacidad de todos los demás sitios que visiten.</p>
                <p className="mb-6"><strong> 10. COMPETENCIA</strong><br />
                    Por el presente, Usted acepta la jurisdicción exclusiva de los Tribunales Ordinarios en lo Comercial con asiento en la Ciudad Autónoma de Buenos Aires, en relación con todas y cada una de las disputas o divergencias relacionadas con el presente Reglamento de Uso del Sitio.
                </p>

            </article>
        </div>);
}

const TermsAndConditions = () => {
    const authContext = useContext(AuthContext);
    const institution = authContext.authData.institution;


    return (
        institution ? <MainLayout currentPageName="Términos y condiciones">
            <TermsAndConditionsContent />
        </MainLayout> : <PreloginLayout>
            <TermsAndConditionsContent />
        </PreloginLayout>

    );
}

export default TermsAndConditions;