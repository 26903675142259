import { useState, useEffect, useCallback } from "react";
import PreloginLayout from "../layouts/PreloginLayout";
import { changePassword, getInstitutions } from "../services/bidiService";
import { TextField, Button, Stack } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SimpleModal from '../components/modals/SimpleModal';
import PasswordField from "../components/PasswordField";


const ChangePassword = () => {
  const navigate = useNavigate();

  const [institution, setInstitution] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { institution_name } = useParams();
  const token = searchParams.get('token');

  useEffect(() => {
    getInstitutions(institution_name).then((response) => {
      const institution = response.data.find(i => i.name === institution_name);

      if (!institution) {
        navigate('/notfound');
      }

      if (!institution.supports_native_login) {
        if (institution.login_methods.login_url) {
          window.location = institution.login_methods.login_url;
          return;
        } else {
          setModalMessage('Para establecer la contraseña debes ingresar mediante tu institución.');
        }
      }

      if (!institution.show_in_front) {
        setModalMessage('Esta biblioteca se encuentra deshabilitada.');
      }

      setInstitution(institution);
      setLoading(false);
    });
  }, [institution_name, navigate]);


  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

		if (event.target.password.value !== event.target.repeat_password.value) {
      setError("Las contraseñas no coinciden");
			setLoading(false);
      return;
		}

    changePassword(token, event.target.password.value).then((response) => {
      window.location = `/account/${institution_name}/login?code=1`;
    }).catch(error => {
      setError("Expiro el link para establecer la contraseña");
      setLoading(false);
    });
  };


  const onCloseModal = useCallback((event) => {
    window.location = `/institutions`;
  });

  return (
    <PreloginLayout loading={loading}>
      <SimpleModal show={modalMessage} onClose={onCloseModal} title={modalMessage} description=""></SimpleModal>

      <div className="p-2 text-left max-w-min">
        <div className="flex justify-center">
          <img className="h-16 mb-6" alt={`Logo de la biblioteca ${institution.name}`} title="Logo de la biblioteca" src={institution.logo} />
        </div>

        <div className="text-sm text-red-600 mb-4">{error}</div>

        <Stack spacing={3} component="form" onSubmit={handleSubmit}>
          <PasswordField id="password" label="Nueva contraseña" name="password" onClickShowPassword={(show) => setShowPassword(show)} required />
          <TextField id="repeat_password" label="Repetir contraseña" name="repeat_password" required type={showPassword ? 'text' : 'password'} variant="standard" />

          <Stack spacing={2} direction="row">
            <div className="min-w-[8.5rem]">

            </div>
            <Button variant="contained" type="submit">Enviar</Button>
          </Stack>
        </Stack>


      </div>
    </PreloginLayout >
  );
}

export default ChangePassword;
