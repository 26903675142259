import { useContext, useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { getReaderUrl } from '../services/bidiService';
import AES from 'crypto-js/aes';
import { AuthContext } from '../contexts/AuthContext';
import queryString from "query-string";


const Reader = ({ download, onClose }) => {
  const authContext = useContext(AuthContext);
  const [reader_url, setReaderUrl] = useState();

  useEffect(() => {
    getReaderUrl(download.downloadUrl).then((response) => {
      const params = {
        asset_id: response.data.asset_id,
        delivery_id: response.data.delivery_id,
        catalog_item: response.data.catalogitem_id,
        key: response.data.key,
        client: authContext.authData.institution.name,
        token_id: authContext.authData.customer_access_token,
        title: encodeURIComponent(download.book.title),
        upc: download.book.isbn,
        hash: download.hash,
        streaming_model: download.book.streaming_model,
        contentType: download.book.format.toUpperCase()
      }

      const baseReaderUrl = (download.book.format === 'pdf') ? process.env.REACT_APP_READER_PDF_URL : process.env.REACT_APP_READER_URL;

      setReaderUrl(`${baseReaderUrl}?${queryString.stringify(params)}`);
    });

    const handler = event => {
      //This event tell you when the reader finish loading if (event.data.action === "viewReaderModal") { }
      if (event.data.action === "closeReaderModal") {
        onClose();
      }
    }

    window.addEventListener("message", handler);

    return () => window.removeEventListener("message", handler)
  }, [])

  return <RemoveScroll>
    <div className="fixed top-0 left-0 w-full h-full z-50">
      <iframe frameBorder="0" scrolling="yes" seamless="seamless" width="100%" height="100%" src={reader_url}></iframe>
    </div>
  </RemoveScroll>
};

export default Reader;