import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Checkbox, FormControlLabel, FormGroup, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Pagination, PaginationItem, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { AuthContext } from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import { getBooks } from "../services/bidiService";
import Book from "../components/Book";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AiOutlineSortAscending } from "react-icons/ai";
import { AiOutlineSortDescending } from "react-icons/ai";
import NoContent from "../components/NoContent";
import SuggestionModal from "../components/modals/SuggestionModal";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { customText } from "../customization/customText";


const Catalog = () => {
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const catFilterTitle = customText('catalog.filters.categories');
  const catSortTitle   = customText('catalog.sorts.category');

  //Book data
  const [books, setBooks] = useState([]);
  const [count, setCount] = useState(0);
  const [pagesCount, setPagesCount] = useState(1);
  const [availableFilters, setAvailableFilters] = useState([
    { name: "contributors", translate: "contributorId", title: "Autor", items: [], showOnCustomGenre: true, showWithoutCustomGenre: true},
    { name: "categories", translate: "categoryId", title: catFilterTitle, items: [], showOnCustomGenre: false,  showWithoutCustomGenre: true},
    { name: "publishers", translate: "publisherId", title: "Editoriales", items: [], showOnCustomGenre: true,  showWithoutCustomGenre: true},
    { name: "custom_genres", translate: "customGenreId", title: catFilterTitle, items: [], showOnCustomGenre: true,  showWithoutCustomGenre: false},
    //{ name: "tags", translate: "tagId", title: "Tags", items: [] }
  ]);
  const [isFirstTime, setIsFirstTime] = useState(true);

  //Query params
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('q');
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [sort, setSort] = useState({ name: searchParams.get('sort') || 'contributor', dir: searchParams.get('dir') || 'asc' });
  const [filters, setFilters] = useState(availableFilters.map(f => {
    let value = searchParams.get(f.name);

    value = (value) ? value.split(',').map(iNum => parseInt(iNum, 10)) : [];
    return { name: f.translate, value }
  }));

  //Interface
  const [sortMenu, setSortMenu] = useState({ open: false, anchor: null });
  const [showFilters, setShowFilters] = useState(false);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const availableSorts = [
    { name: 'contributor', title: 'Autor' },
    { name: 'title', title: 'Título' },
    // { name: institution.has_custom_genres ? "custom_genre" : "category", title: institution.has_custom_genres ? "Categoría" : catSortTitle },
    { name: institution.has_custom_genres ? "custom_genre" : "category", title: catSortTitle },
    { name: 'publisher', title: 'Editorial' },
    { name:  'year', title: 'Año' },
  ];

  const updateQueryParams = (qs) => {
    const params = { ...queryString.parse(window.location.search), ...qs };
    const url = window.location.pathname + '?' + queryString.stringify(params);
    window.scroll({ top: 0 });
    window.history.pushState({ path: url }, '', url);
  };

  const onChangePage = (jumpToPage) => {
    updateQueryParams({ page: jumpToPage });
    setPage(jumpToPage);
  };

  const onClickSortMenu = (sortMenuItem) => {
    const dir = (sortMenuItem.name === sort.name && sort.dir === 'asc') ? 'desc' : 'asc';

    updateQueryParams({ page: 1, sort: sortMenuItem.name, dir: dir });
    setPage(1);
    setSort({ name: sortMenuItem.name, dir });
    setSortMenu({ open: false });
  };

  const onClickFilterCheck = (event, filter, field) => {
    const filterParams = {};

    availableFilters.forEach(af => {
      filterParams[af.name] = filters.find(f => f.name === af.translate).value;
      if (af.name === filter.name) {
        if (event.target.checked) {
          filterParams[af.name].push(field.id);
        } else {
          filterParams[af.name] = filterParams[af.name].filter((value) => value !== field.id);
        }
      }

      filterParams[af.name] = filterParams[af.name].join(',');
    });

    const filtersNew = [...filters];
    const filterId = filters.findIndex(f => f.name === filter.translate);
    if (event.target.checked) {
      filtersNew[filterId].value.push(field.id);
    } else {
      filtersNew[filterId].value = filtersNew[filterId].value.filter((value) => value !== field.id);
    }
    setPage(1);
    setFilters(filtersNew);
    updateQueryParams({ page: 1, ...filterParams });
  };

  useEffect(() => {
    const limit = institution.maxItemsPerPage || 36;
    const offset = (page - 1) * limit;

    setLoading(true);
    getBooks(institution.name, offset, limit, query, true, sort.name, sort.dir, filters, authContext.authData.catalogs, institution.has_custom_genres).then((response) => {
      setBooks(response.data.books);
      setCount(response.data.count);
      setPagesCount(Math.ceil(response.data.count / limit));

      if (isFirstTime) {
        setAvailableFilters(availableFilters.map(f => {

          // Order filter items ASC.
          let filterItems = response.data.filtersInfo[f.name];
          filterItems.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;     // names are equals
          });

          return { ...f, items: filterItems } }));
          
        setIsFirstTime(false);
      }
      setLoading(false);
    });
  }, [page, sort, filters])


  return <MainLayout loading={loading} currentPageKey="Catálogo" currentPageName="Catálogo">
    <SuggestionModal show={showSuggestionModal} onClose={() => setShowSuggestionModal(false)} />
    <div className="flex justify-center py-8">
      {!count && <NoContent>
        No se encontró ningún resultado que contenga el término de búsqueda que ingresaste. En caso de que no encuentres el título que buscas puedes hacernos una sugerencia para incorporarlo.
        <Button variant="contained" fullWidth onClick={() => setShowSuggestionModal(true)}>Hacer sugerencia</Button>
      </NoContent>}

      {/* Filters */}
      {!!count && <>
        <div className={(showFilters) ? "overflow-hidden flex-shrink-0 flex-grow" : "overflow-hidden w-0"}>
          <div className={"max-w-[14rem] border-r h-full mr-6 transform transition duration-700 ease-in-out -translate-x-full" + ((!showFilters) ? "" : "translate-x-0")}>
            <div className="flex flex-col gap-4 pt-1">

              <div className="mx-3">
                <div className="flex justify-between">
                  <h3 className="text-2xl text-bold">Refinar</h3>
                  <IconButton aria-label="filtrar" onClick={() => setShowFilters(!showFilters)}>
                    <ArrowBackIcon />
                  </IconButton>
                </div>

                Agregue algún filtro
              </div>

              <div className="pl-1">
                {availableFilters.filter(
                  filter => (institution.has_custom_genres && filter.showOnCustomGenre) || (!institution.has_custom_genres && filter.showWithoutCustomGenre)
                  ).map((f, index) =>
                  <Accordion key={index} expanded={expanded === `panel${index}`} onChange={() => (expanded !== `panel${index}`) ? setExpanded(`panel${index}`) : setExpanded(false)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    ><Typography className="font-bold">{f.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="flex flex-col">
                      <FormGroup>
                        {f.items && f.items.map((item, index) =>
                          <FormControlLabel key={index}
                            control={<Checkbox
                              checked={filters.find(filter => filter.name === f.translate).value.includes(item.id)}
                              onChange={(e) => onClickFilterCheck(e, f, item)}
                              inputProps={{ 'aria-label': item.name }} />}
                            label={item.name} />)
                        }
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            </div>
          </div>
        </div>


        {/* Title, sort and filter buttons */}
        <div className={(showFilters) ? "hidden sm:block" : ""}>
          <div className="flex items-center gap-2">
            <div className="flex-grow">
              <h2 className="text-2xl font-bold">{query ? `Resultados encontrados (${count} contenidos)` : 'Catálogo'}</h2>
            </div>
            {!!count && <ButtonGroup variant="">
              {!showFilters && <IconButton aria-label="filtraIr" onClick={() => setShowFilters(!showFilters)}>
                <FilterAltIcon />
              </IconButton>}
              <Button
                aria-controls={sortMenu.open ? 'sort-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={sortMenu.open ? 'true' : undefined}
                className="flex-shrink whitespace-nowrap" size="small"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={(event) => { setSortMenu({ open: true, anchor: event.currentTarget }) }}
              >
                Ordenar por
              </Button>
              <Menu id="sort-menu" MenuListProps={{ 'aria-labelledby': 'Ordenar resultados' }}
                open={sortMenu.open} anchorEl={sortMenu.anchor} onClose={() => { setSortMenu({ open: false, anchor: null }) }}>
                {availableSorts.map((aSort, index) =>
                  <MenuItem key={index} onClick={() => onClickSortMenu(aSort)}>
                    <ListItemText className="mr-4">{aSort.title}</ListItemText>
                    <ListItemIcon>
                      <Icon aria-label={sort.dir === 'asc' ? 'Descendente' : 'Ascendente'} color={(aSort.name === sort.name) ? 'primary' : ''}>
                        {(aSort.name === sort.name && sort.dir === 'asc') ? <AiOutlineSortDescending /> : <AiOutlineSortAscending />}
                      </Icon>
                    </ListItemIcon>
                  </MenuItem>)}
              </Menu>
            </ButtonGroup>}
          </div>

          {/* Results */}
          <div>
            <div className="flex flex-row flex-wrap gap-3 py-4 max-w-5xl">
              {books.map((book, index) => <div className="flex flex-1" key={index}><Book book={book} has_custom_genres={institution.has_custom_genres} /></div>)}
            </div>
            {!!count && <div className="flex justify-center">
              <Pagination count={pagesCount} page={page} size="large" onChange={(e, p) => onChangePage(p)} color="primary"
                showFirstButton showLastButton
                renderItem={(item) => (
                  <PaginationItem color="primary"
                    components={{ next: ArrowForwardIosOutlinedIcon, last: KeyboardDoubleArrowRightOutlinedIcon, first: KeyboardDoubleArrowLeftOutlinedIcon, previous: ArrowBackIosOutlinedIcon }}
                    {...item}
                  />
                )} />
            </div>}
          </div>
        </div>
      </>}
    </div>
  </MainLayout>
}

export default Catalog;