import { CircularProgress } from "@mui/material";
import { useState } from "react";
import ReadingKey from "../ReadingKey";
import SimpleModal from "./SimpleModal";

const ReadingKeyModal = ({ onClose, ...props }) => {
  const [loading, setLoading] = useState(false);

  return <SimpleModal {...props} show={true}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <ReadingKey onSuccess={onClose}></ReadingKey>}

  </SimpleModal>
};

export default ReadingKeyModal;