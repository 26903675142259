import { CircularProgress } from "@mui/material";
import BidiLogo from './../assets/images/bidi169x57.png';

const PreloginLayout = ({ loading, ...props }) => {
  return (
    <div className="flex flex-col flex-grow justify-center items-center bg-[#f7f9f9]">
      <h1 className="hidden">Bidi</h1>
      {loading && <CircularProgress />}
      {!loading &&
        <div className="bg-white shadow-xl mt-14 p-10 max-w-7xl text-center font-sans text-base">
          {props.children}
        </div>
      }
      <div className="flex justify-center">
        <img className="text-center h-10 my-8" alt="Bidi" src={BidiLogo} />
      </div>
    </div>
  );
}

export default PreloginLayout;