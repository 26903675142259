import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import MainLayout from '../layouts/MainLayout';
import { getCollection } from '../services/bidiService';
import { useParams, useSearchParams } from 'react-router-dom';
import Card from '../components/Card';

const Collection = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);

  const [collection, setCollection] = useState({ items: [] });
  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getCollection(authContext.authData.institution.name, searchParams.get('type'), id).then((data) => {
      setCollection(data.collections[0]);
      setLoading(false);
    });
  }, []);

  return (
    <MainLayout loading={loading} currentPageName={collection?.title}>
      <div className="py-4">
        <div className="flex items-center">
          <div className="flex-grow">
            <h2 className="text-2xl font-bold">{collection.title}</h2>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-3 py-4 max-w-5xl">
          {collection.items.map((i, index) => <Card key={index} id={i.external_id} book={i.book} />)}
        </div>
      </div>

    </MainLayout>
  );
};

export default Collection;