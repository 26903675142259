import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { createSuggestion } from "../../services/bidiService";
import SimpleModal from "./SimpleModal";

const SuggestionModal = (props) => {
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    createSuggestion(institution.name, user?.id, event.target.author.value, event.target.title.value, event.target.description.value, "content").then(() => {
      props.onClose();
    }).catch(() => setLoading(false));

  };

  return <SimpleModal {...props}>
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <Box>
        <Typography>Lamentamos no haber podido encontrar el título que buscabas, pero puedes escribirnos para que evaluemos incorporar el título que estas buscando.</Typography>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1 },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            required
            name="title"
            id="outlined-required"
            label="Titulo"
          />
          <TextField
            required
            name="author"
            id="outlined-required"
            label="Autor"
          />
          <TextField
            fullWidth
            id="outlined-required"
            name="description"
            label="Descripción"
            placeholder="Escribe aquí algun dato adicional que quieras agregar"
          />
          <Button variant="contained" fullWidth type="submit">Enviar sugerencia</Button>
        </Box>
      </Box>}
  </SimpleModal>
};

export default SuggestionModal;