import FindawayPlayer from "../FindawayPlayer";
import SimpleModal from "./SimpleModal";

const PlayerModal = ({ download, onClose }) => {
  return <SimpleModal show={true} onClose={onClose} title="Reproductor de audiolibros">
    <div className="mt-6">
      <FindawayPlayer download={download} />
    </div>
  </SimpleModal>;
}

export default PlayerModal;