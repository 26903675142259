import MainLayout from "../layouts/MainLayout";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { getDownloads } from "../services/bidiService";
import NoContent from "../components/NoContent";
import { Button } from "@mui/material";
import Download from "../components/Download";

function MyBooks() {
  const authContext = useContext(AuthContext);

  const institution = authContext.authData.institution;
  const user = authContext.authData.user;
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDownloads(authContext.authData.customer_access_token, institution.name, user.id).then((response) => {
      setDownloads(response.data.downloads);
      setLoading(false);
    });
  }, []);


  return (
    <MainLayout loading={loading} currentPageKey="Mis libros" currentPageName="Mis libros">
      <div className="py-8">
        {(downloads.length === 0) ? <NoContent>
          En esta sección visualizarás todos los libros que tomaste prestados o has descargado. Recuerda que los libros expirados los encontrarás en el Historial.
          <Button variant="contained" fullWidth href="/catalogo">Ver catálogo</Button>
        </NoContent> :
          <div>
            <div className="flex items-center">
              <div className="flex-grow">
                <h2 className="text-2xl font-bold">Mis libros</h2>
              </div>
            </div>
            <div className="py-4">
              <div className="flex flex-wrap gap-6">
                {downloads.map((download, index) => {
                  return (<Download key={index} download={download} onReturn={(id) => setDownloads(downloads.filter(d => d.id !== id))} />);
                })}
              </div>
            </div>
          </div>}
      </div>
    </MainLayout>
  );
}

export default MyBooks;