import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { confirmAccount } from "../services/bidiService";

const ConfirmAccount = () => {
  const { institution_name, token } = useParams();

  useEffect(() => {
    confirmAccount(token).then((response) => {
      window.location = `/account/${institution_name}/login?code=0`;
    }).catch(error => {
      alert("Error al intentar verificar la cuenta");
    });
  }, [])

  return null;
}

export default ConfirmAccount;