import { useContext, useEffect, useState } from "react";
import { getAudiobookData, getAudiobookStatus, syncAudiobookStatus } from "../services/bidiService";

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Replay30Icon from '@mui/icons-material/Replay30';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward30Icon from '@mui/icons-material/Forward30';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { CircularProgress, IconButton, Slider } from "@mui/material";

import findaway from 'findaway-sdk';
import moment from "moment";
import { AuthContext } from "../contexts/AuthContext";

const FindawayPlayer = ({ download, onClose }) => {

  const authContext = useContext(AuthContext);

  const [player, setPlayer] = useState({});
  const [loading, setLoading] = useState(true);

  const [timeElapsed, setTimeElapsed] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    let localPlayer;
    let firstLoad = true;

    Promise.all([
      getAudiobookData(download.downloadUrl),
      getAudiobookStatus(authContext.authData.customer_access_token, download.book.isbn)
    ]).then(([response, audiobook_status]) => {
      findaway.init({
        session_key: response.data.session_key,
        debug: true
      }).then(() => {
        localPlayer = new findaway.Player(response.data.account_id);

        localPlayer.on('playback:started', () => {
          if (firstLoad) {
            const position = (audiobook_status && audiobook_status.position) || 0
            setLoading(false);
            localPlayer.setPosition(position);
            firstLoad = false;
          }
          setIsPlaying(true);
        });
        localPlayer.on('playback:paused', () => setIsPlaying(false));
        localPlayer.on('trackCompleted', () => localPlayer.next());
        localPlayer.on('timeChanged', (newTime) => {
          setTimeElapsed(newTime);

          //sync each 10 seconds
          if (!firstLoad && Math.floor(newTime % (10 * 1000)) < 300) {
            syncAudiobookStatus(authContext.authData.customer_access_token, download.book.isbn, download.deliveryId, localPlayer.currentTrack, localPlayer.currentTitle.chapters.length, localPlayer.getPosition(), localPlayer.getLength()).then(() => {
            });
          }
        });
        localPlayer.on('trackChanged', (chapter) => {
          if (!localPlayer.isPlaying()) {
            localPlayer.play();
          }
          setTotalTime(localPlayer.getLength());
        });

        /*
          Other events: bookCompleted, volumeChanged, sampleCompleted, load:success, playback:started, playback:paused, unloaded, load:recoveryFailed, load:recoveryStart, load:recoverySuccess, load:recoveryEnd
        */

        const audiobook = new findaway.Audiobook(response.data.audiobook_id, response.data.account_id);
        audiobook.load().then(() => {
          audiobook.loadPlaylist(null, response.data.license_id).then(playlistResponse => {
            localPlayer.load(audiobook).then(() => {
              const chapter = (audiobook_status && audiobook_status.chapter) || 0;

              localPlayer.changeTrack(chapter); //autoplay: trigger trackChanged

              setPlayer(localPlayer);
            });
          });
        });
      });
    });

    return () => localPlayer.unload();
  }, []);

  const setPosition = (e) => {
    player.setPosition((e.target.value * totalTime) / 100);
  }

  const sliderHover = () => {
    return moment.utc(moment.duration(timeElapsed).as('milliseconds')).format("HH:mm:ss");
  }

  return <div className="border rounded px-4 py-2" >
    {loading ? <div className="flex justify-center py-8"><CircularProgress /></div> :
      <>
        <div className="flex items-center gap-4">
          <div>{moment.utc(moment.duration(timeElapsed).as('milliseconds')).format("HH:mm:ss")}</div>
          <Slider value={((timeElapsed / totalTime) * 100) || 0} aria-label="Barra de reproducción" valueLabelFormat={(e) => sliderHover(e)} valueLabelDisplay="auto" onChange={(e) => setPosition(e)} />
          <div>{moment.utc(moment.duration(totalTime).as('milliseconds')).format("HH:mm:ss")}</div>
        </div>
        <div className="flex justify-center text-black">
          <IconButton color="primary" aria-label="Retroceder un capítulo" onClick={() => player.currentTrack > 0 && player.prev()}>
            <SkipPreviousIcon />
          </IconButton>
          <IconButton color="primary" aria-label="Retroceder 30 segundos" onClick={() => { player.setPosition(player.getPosition() - Math.min(30 * 1000, totalTime - player.getPosition())); }}>
            <Replay30Icon />
          </IconButton>

          {!isPlaying ? <IconButton color="primary" aria-label="Reproducir" onClick={() => player.play && player.play()}>
            <PlayArrowIcon />
          </IconButton> : <IconButton color="primary" aria-label="Pausar" onClick={() => player.pause()}>
            <PauseIcon />
          </IconButton>}

          <IconButton color="primary" aria-label="Adelantar 30 segundos" onClick={() => { player.setPosition(player.getPosition() + Math.min(30 * 1000, totalTime - player.getPosition())); }}>
            <Forward30Icon />
          </IconButton>
          <IconButton color="primary" aria-label="Avanzar un capítulo" onClick={() => player.currentTrack < player.currentTitle.chapters.length && player.next()}>
            <SkipNextIcon />
          </IconButton>
        </div>
      </>}
  </div>
};

export default FindawayPlayer;