import { Routes, Route } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { useState, useContext, useEffect } from 'react';
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module'

import Home from './pages/Home';
import Institutions from './pages/Institutions';
import Login from './pages/Login';
import PublicAccess from './pages/PublicAccess';
import LoginByToken from './pages/LoginByToken';
import Collection from './pages/Collection';
import Help from './pages/Help';
import TermsAndConditions from './pages/TermsAndConditions';

import NotFound from './pages/NotFound';
import Tutorial from './pages/Tutorial';
import Faq from './pages/Faq';
import MyBooks from './pages/MyBooks';
import Logout from './pages/Logout';
import Book from './pages/Book';
import Catalog from './pages/Catalog';
import Register from './pages/Register';
import MyReservations from './pages/MyReservations';
import History from './pages/History';
import MyFavourites from './pages/MyFavourites';
import ForgetPassword from './pages/ForgetPassword';
import ChangePassword from './pages/ChangePassword';
import ChangePasswordMobileApp from './pages/ChangePasswordMobileApp';
import Profile from './pages/Profile';
import DeepLink from './pages/DeepLink';
import ConfirmAccount from './pages/ConfirmAccount';
import moment from 'moment';
import Cookies from 'js-cookie';
import ErrorLogin from './pages/ErrorLogin';



function RequireAuth({ children, userlogged }) {
  const authContext = useContext(AuthContext);

  if (authContext.loading) return null;

  if (!authContext.authData.institution) {
    window.location = "/home";
    return null;
  }

  if (!authContext.authData.user && userlogged) {
    window.location = `/account/${authContext.authData.institution.name}/login?r=${window.location.pathname}`;
  }

  return children;
}

function App() {
  const [authData, setAuthData] = useState({});
  const [loading, setLoading] = useState(true);

  const login = (data, redirect_url = "/") => {
    setAuthData(data);
    Object.entries(data).forEach(([key, value]) => {
      window.localStorage.setItem(key, JSON.stringify(value));
    });

    const redirect_url_from_cookie = Cookies.get('redirect_url');
    Cookies.remove('redirect_url');

    window.location = redirect_url_from_cookie || redirect_url;
  };

  const logout = () => {
    const institution = JSON.parse(localStorage.getItem('institution'));

    //Get the redirect url
    let redirect = "/institutions";
    if (institution) {
      redirect = (!!institution.logoutRedir) ? institution.logoutRedir : `/${institution.name}`;
    }

    //Clear session data
    window.localStorage.clear();

    //Redirect user to logout page
    window.location = redirect;
  };

  const updateCustomerAccessToken = (access_token) => {
    window.localStorage.setItem("customer_access_token", JSON.stringify(access_token));
  }

  const updateUser = (userDataUpdate) => {
    const userData = JSON.parse(window.localStorage.getItem("user"));
    window.localStorage.setItem("user", JSON.stringify({ ...userData, ...userDataUpdate }));
  }

  const updateCommands = (commands) => {
    window.localStorage.setItem("commands", JSON.stringify(commands));
  }

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID });
    }
  }, []);


  useEffect(() => {
    //Get user data from localstorage
    const authDataObject = { loading: false };

    Object.keys(window.localStorage).forEach(key => {
      try {
        authDataObject[key] = JSON.parse(window.localStorage.getItem(key));
      } catch (e) { }
    });

    setAuthData(authDataObject);
    setLoading(false);

    if (authDataObject.user) {
      const currentDatetime = moment().unix();
      const remainSeconds = Math.max(parseInt(JSON.parse(localStorage.getItem('ls_expiration'))) - currentDatetime, 0);

      const logoutTimer = setTimeout(() => {
        logout();
      }, remainSeconds * 1000);

      return () => { clearTimeout(logoutTimer) };
    }
  }, []);

  return (
    <div className="h-screen flex flex-col font-zilla">
      <AuthContext.Provider value={{ authData, login, logout, updateCustomerAccessToken, updateUser, updateCommands, loading: loading }}>
        <Routes>
          <Route index element={<RequireAuth><Home /></RequireAuth>} />

          <Route path="/mislibros" element={<RequireAuth userlogged><MyBooks /></RequireAuth>} />
          <Route path="/misreservas" element={<RequireAuth userlogged><MyReservations /></RequireAuth>} />
          <Route path="/misfavoritos" element={<RequireAuth userlogged><MyFavourites /></RequireAuth>} />
          <Route path="/historial" element={<RequireAuth userlogged><History /></RequireAuth>} />
          <Route path="/perfil" element={<RequireAuth userlogged><Profile /></RequireAuth>} />
          <Route path="/logout" element={<RequireAuth><Logout /></RequireAuth>} />

          <Route path="/catalogo" element={<RequireAuth><Catalog /></RequireAuth>} />
          <Route path="/busqueda" element={<RequireAuth><Catalog /></RequireAuth>} />
          <Route path="/libro/:id" element={<RequireAuth><Book /></RequireAuth>} />
          <Route path="/collection/:id" element={<RequireAuth><Collection /></RequireAuth>} />
          <Route path="/ayuda" element={<RequireAuth><Help /></RequireAuth>} />
          <Route path="/tutorial" element={<RequireAuth><Tutorial /></RequireAuth>} />
          <Route path="/preguntasfrecuentes" element={<RequireAuth><Faq /></RequireAuth>} />
          <Route path="/terminosycondiciones" element={<TermsAndConditions />} />

          <Route path="/institutions" element={<Institutions />} />
          <Route path="/home" element={<Institutions />} />

          <Route path="/:institution_name" element={<Login />} />
          <Route path="/account/:institution_name/login" element={<Login />} />
          <Route path="/account/:institution_name/login/:login_method" element={<Login />} />
          <Route path="/account/:institution_name/registro" element={<Register />} />
          <Route path="/account/:institution_name/forget" element={<ForgetPassword />} />
          <Route path="/account/:institution_name/reset" element={<ChangePassword />} />
          <Route path="/customer/forget" element={<ChangePasswordMobileApp />} />
          <Route path="/account/confirm/:institution_name/:token" element={<ConfirmAccount />} />

          <Route path="/tokenlogin" element={<LoginByToken />} />
          <Route path="/token/login" element={<LoginByToken />} />
          <Route path="/open_access/:institution_name" element={<PublicAccess />} />
          <Route path="/app_deeplink/download" element={<DeepLink />} />

          <Route path="/errorlogin" element={<ErrorLogin />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path='*' exact={true} element={<NotFound />} />
        </Routes>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
