import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const Logout = () => {
  const authContext = useContext(AuthContext);

  authContext.logout();

  return <></>;
};

export default Logout;