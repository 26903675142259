import defaultTexts              from "./defaultTexts";
import conectarigualdadtestTexts from './conectarigualdadtestTexts';
import conectarigualdadTexts     from './conectarigualdadTexts';

let institutionName = "";           // Not logged in yet. Use defaults.

/**
 * Return the value of an oject propertie in keys path.
 * 
 * @param object    obj       Object to inspect.
 * @param string    keysPath  Keys path of value to reach. 
 *                                  Format : prop1.val1.subprop1.subval1...'
 * @returns any
 */
const keyValue = (obj = {}, key = '') => {
    let   value = undefined;            // Value to be returned.
    const props = key.split('.');       // Keys path separator.

    // Iterates thru keys path down to final value.
    for (let i = 0; i < props.length; i++) {
        if (i === 0 ) {
            value = obj;
        }
        value = value[props[i]];
        if (value === undefined ) {
            console.log( 'undefined propertie "' + props[i] + '" traying to get it\'s value.');
            break;
        }
    }

    return value;
}

/**
 * Return Custom text for logged institution or default one.
 * @param {string} key      Keys path of propertie to reach.
 * @returns any             Value of last propertie in keys path.
 */
const customText = (key) => {

    const institution = JSON.parse(localStorage.getItem('institution'));
    let keyText         = "";           // Value to be returned.
    let customText      = undefined;    // No custom text get it.

    // Get default text.
    keyText = keyValue(defaultTexts, key);


    // Try to search for custom texts from the institution.
    if (institution && !!institution.name) {
        institutionName = institution.name;

        // @TODO:   Load dynamicaly only component depending on 
        //          institution name and then get key value.
        if ( institutionName === 'conectarigualdadtest') {
            customText = keyValue(conectarigualdadtestTexts, key);
            if (customText) {
                keyText = customText;
            }
        }
        if ( institutionName === 'conectarigualdad') {
            customText = keyValue(conectarigualdadTexts, key);
            if (customText) {
                keyText = customText;
            }
        }
    }

    return keyText;
}


export { customText }; 