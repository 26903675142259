import { useState, useEffect, useCallback } from "react";
import PreloginLayout from "../layouts/PreloginLayout";
import { changePasswordMobileApp } from "../services/bidiService";
import { TextField, Button, Stack } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import SimpleModal from '../components/modals/SimpleModal';
import PasswordField from "../components/PasswordField";
import BidiLogo from './../assets/images/bidi169x57.png';


const ChangePasswordMobileApp = () => {
  const navigate = useNavigate();

  const [institution, setInstitution] = useState({ name: 'bidi', logo: BidiLogo });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');

  useEffect(() => {
    setLoading(false);
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    if (event.target.password.value !== event.target.repeat_password.value) {
      setError("Las contraseñas no coinciden");
      setLoading(false);
      return;
    }

    changePasswordMobileApp(token, event.target.password.value).then((response) => {
      setModalMessage("La contraseña se cambió con éxito. Puedes volver a ingresar en la app.")
    }).catch(error => {
      setError("Expiro el link para establecer la contraseña");
      setLoading(false);
    });
  };


  const onCloseModal = useCallback((event) => {
    window.location = `/`;
  });

  return (
    <PreloginLayout loading={loading}>
      <SimpleModal show={modalMessage} onClose={onCloseModal} title={modalMessage} description=""></SimpleModal>

      <div className="p-2 text-left max-w-min">
        <div className="flex justify-center">
          <img className="h-16 mb-6" alt={`Logo de la biblioteca ${institution.name}`} title="Logo de la biblioteca" src={institution.logo} />
        </div>

        <div className="text-sm text-red-600 mb-4">{error}</div>

        <Stack spacing={3} component="form" onSubmit={handleSubmit}>
          <PasswordField id="password" label="Clave de lectura" name="password" onClickShowPassword={(show) => setShowPassword(show)} required />
          <TextField id="repeat_password" label="Repetir clave de lectura" name="repeat_password" required type={showPassword ? 'text' : 'password'} variant="standard" />

          <Stack spacing={2} direction="row">
            <div className="min-w-[8.5rem]">

            </div>
            <Button variant="contained" type="submit">Enviar</Button>
          </Stack>
        </Stack>


      </div>
    </PreloginLayout >
  );
}

export default ChangePasswordMobileApp;
