const conectarigualdadtestTexts = {
  menu: {
    home: "Inicio",
    about: "Acerca de",
    profile: "Clave de lectura",
  },
  breadcrumbs: {
    reservations: "Reservas",
    favorites: "Favoritos",
    profile: "Clave de lectura",
  },
  // Page elements.
  profile: {
    reading_key_subtitle:
      "Configurá tu clave para usar Bidi App y Adobe Digital Editions",
    reading_key_text1:
      "Tanto para usar la aplicación Bidi App como para descargar libros y leer fuera de línea es necesario que crees una clave de lectura. Esta clave no es la misma que la de tu usuario de acceso, sino una específica que te será requerida solo en Bidi App o cuando configures Adobe Digital Editions para leer desconectado/a en tu equipo. Generala a continuación.",
    reading_key_text2:
      "Recordá que cada vez que configures una clave de lectura,deberás actualizarla tanto en Bidi App como en el acceso a AdobeDigital Editions, en cualquier dispositivo que hayas vinculado.",
    password_changed:
      "La contraseña se cambió con éxito. Podés utilizarla para ingresar a la aplicación.",
  },
  favorites: {
    title: "Favoritos",
  },
  catalog: {
    filters: {
      categories: "Etiquetas",
    },
    sorts: {
      category: "Etiqueta",
    },
  },
  book: {
    contributor_data: "Datos bibliográficos",
  },
};

export default conectarigualdadtestTexts;
