import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import MainLayout from '../layouts/MainLayout';
import { getCarrousels, getDownloads, getReservations, getTags } from '../services/bidiService';
import Collection from '../components/Collection';
import { Button, Chip } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Reservation from '../components/Reservation';
import Download from '../components/Download';

const Home = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const institution = authContext.authData.institution;
  const user = authContext.authData.user;

  const [collections, setCollections] = useState([]);
  const [tags, setTags] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const promises = [
      getCarrousels(institution.name),
      getTags(institution.name, institution.maxHometags)
    ];

    if (user && authContext.authData.customer_access_token) {
      promises.push(getDownloads(authContext.authData.customer_access_token, institution.name, user.id));
      promises.push(getReservations(authContext.authData.customer_access_token, institution.name, user.id));
    }

    Promise.all(promises).then(([collections, tags, responseDownloads, responseReservations]) => {
      setCollections(collections);
      setTags(tags);
      if (user && authContext.authData.customer_access_token) {
        setDownloads(responseDownloads.data.downloads);
        setReservations(responseReservations.data.reservations)
      }
      setLoading(false);
    });

  }, []);

  return (
    <MainLayout loading={loading}>
      <div className="py-4">
        {/* Banner top */}
        {institution.bannerTopName && <div className="my-6 flex justify-center">
          <a href={institution.bannerTopLink} target="_blank" rel="noreferrer" title="Ir a la página del banner superior">
            <img alt="Banner superior" className="w-full" title="Banner" src={`${process.env.REACT_APP_IMAGES_BASE_URL}/${institution.bannerTopName}`} />
          </a>
        </div>}

        {/* Collections */}
        {collections.map((c, index) => {
          return (<div key={index} className="w-full pb-8" style={c.level ? { borderLeftColor: "#b3b3b3", borderLeftWidth: "medium", paddingLeft: "3em" } : {}}>
            <Collection data={c}></Collection>
          </div>);
        })}

        {/* Catalog button */}
        <div className="flex justify-center m-12">
          <Button variant="contained" component={Link} to="/catalogo">Ver catálogo</Button>
        </div>

        {/* My books */}
        {user &&
          (downloads.length === 0 && reservations.length === 0 ? <div className="m-auto text-center max-w-xl">
            En este apartado visualizarás los libros que tomes prestados, reservados o descargados. Ingresa al catálogo y comienza a descargar tus libros.
          </div> : <div>
            <div className="flex items-center my-4">
              <div className="flex-grow">
                <h2 className="text-2xl font-bold">Mis libros</h2>
              </div>
            </div>
            <div className="flex flex-wrap gap-6">
              {downloads.map((download, index) => {
                return (<Download key={index} download={download} onReturn={(id) => setDownloads(downloads.filter(d => d.id !== id))} />);
              })}
              {reservations.map((reservation, index) => {
                return (<Reservation key={index} reservation={reservation} onCancel={(id) => setReservations(reservations.filter(r => r.id !== id))} />);
              })}
            </div>
          </div>)}

        {/* Tags */}
        {institution.tagsTitle && <div className="bg-[#DFDFDF] text-center font-normal text-[#525252] pt-2 pb-4 mt-8 mb-4">{institution.tagsTitle}</div>}
        <div id="homeTags" className="flex flex-wrap justify-center gap-x-8 gap-y-4 mb-10">
          {tags.map((tag, index) => {
            return (<Chip key={index} label={tag.title} variant="outlined" className="uppercase" style={{ "borderRadius": "0.25rem", "border": "2px solid #666" }} onClick={() => { navigate(`/collection/${tag.id}?type=tag`) }} />);
          })}
        </div>

        {/* Banner Bottom */}
        {institution.bannerBottomName && <div className="my-6 flex justify-center">
          <a href={institution.bannerBottomLink} target="_blank" rel="noreferrer" title="Ir a la página del banner inferior">
            <img alt="Banner inferior" className="w-full" title="Banner" src={`${process.env.REACT_APP_IMAGES_BASE_URL}/${institution.bannerBottomName}`} />
          </a>
        </div>}
      </div>
    </MainLayout>
  );
};

export default Home;