import { useState, useEffect, useCallback } from "react";
import PreloginLayout from "../layouts/PreloginLayout";
import { forgetPassword, getInstitutions } from "../services/bidiService";
import { TextField, Button, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SimpleModal from '../components/modals/SimpleModal';


const messages = [
		"Se ha detectado que tu cuenta no se encuentra habilitada. Comunicate a la brevedad con tu Administrador.",
		"Te hemos enviado un email a tu cuenta para que puedas cambiar tu contraseña. En caso de no recibirlo revisa tu carpeta de spam.",
		"Hemos detectado que tu cuenta no se encuentra activa. Te enviaremos un email para activarla. En caso de no recibirlo revisa tu carpeta de spam."
];

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [institution, setInstitution] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalRedirect, setModalRedirect] = useState("");

  const { institution_name } = useParams();

  useEffect(() => {
    getInstitutions(institution_name).then((response) => {
      const institution = response.data.find(i => i.name === institution_name);

      if (!institution) {
        navigate('/notfound');
      }

      if (!institution.supports_native_login) {
        if (institution.login_methods.login_url) {
          window.location = institution.login_methods.login_url;
          return;
        } else {
          setModalRedirect(true);
          setModalMessage('Para recuperar la contraseña debes ingresar mediante tu institución.');
        }
      }

      if (!institution.show_in_front) {
        setModalRedirect(true);
        setModalMessage('Esta biblioteca se encuentra deshabilitada.');
      }

      setInstitution(institution);
      setLoading(false);
    });
  }, [institution_name, navigate]);


  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    forgetPassword(event.target.email.value, institution_name).then((response) => {
      setModalMessage(messages[response.data.code]);
      setLoading(false);
    }).catch(error => {
      setError("El email no se encuentra registrado");
      setLoading(false);
    });
  };


  const onCloseModal = useCallback((event) => {
    if (modalRedirect) {
      window.location = "/institutions";
    }
    window.location = `/account/${institution_name}/login`;
  });

  return (
    <PreloginLayout loading={loading}>
      <SimpleModal show={modalMessage} onClose={onCloseModal} title={modalMessage} description=""></SimpleModal>

      <div className="p-2 text-left max-w-min">
        <div className="flex justify-center">
          <img className="h-16 mb-6" alt={`Logo de la biblioteca ${institution.name}`} title="Logo de la biblioteca" src={institution.logo} />
        </div>

        <Stack spacing={3} component="form" onSubmit={handleSubmit}>
          <TextField id="standard-basic" label="Email" name="email" type="email" required variant="standard"
            error={!!error} helperText={error} />

          <Stack spacing={2} direction="row">
            <div className="min-w-[8.5rem]">

            </div>
            <Button variant="contained" type="submit">Enviar</Button>
          </Stack>
        </Stack>


      </div>
    </PreloginLayout >
  );
}

export default ForgetPassword;
