import { useState } from "react";
import { FormControl, InputLabel, InputAdornment, IconButton, Input } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordField = ({ id, label, required, onClickShowPassword, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    onClickShowPassword && onClickShowPassword(!showPassword);
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (<FormControl variant="standard">
    <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
    <Input
      id={id}
      type={showPassword ? 'text' : 'password'}
      required={required}
      {...props}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Cambiar visibilidad de la contraseña"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>);
};

export default PasswordField;