const defaultTexts = {
  menu: {
    home: "Inicio",
    about: "Acerca de",
    profile: "Mi Perfil",
  },
  breadcrumbs: {
    reservations: "Mis Reservas",
    favorites: "Mis Favoritos",
    profile: "Configuración",
  },
  // Page elements.
  profile: {
    reading_key_subtitle:
      "Bidi App y Adobe Digital Editions - Clave de lectura",
    reading_key_text1:
      "Para leer tus libros, completa los siguientes datos y descarga gratis la App de Bidi en tus dispositivos móviles y Adobe Digital Editions para tu PC.",
    reading_key_text2:
      "Recuerda que al actualizar tu Contraseña App, deberás modificarla en todos tus dispositivos autorizados.",
    password_changed:
      "La contraseña se cambió con éxito. Puedes utilizarla para ingresar a la app.",
  },
  favorites: {
    title: "Mis favoritos",
  },
  catalog: {
    filters: {
      categories: "Categorías",
    },
    sorts: {
      category: "Categoría",
    },
  },
  book: {
    contributor_data: "Datos del autor",
  },
};

export default defaultTexts;
