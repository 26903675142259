import MainLayout from "../layouts/MainLayout";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { getDownloads } from "../services/bidiService";
import NoContent from "../components/NoContent";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from "@mui/material";
import DeleteHistoryModal from "../components/modals/DeleteHistoryModal";

function History() {
  const authContext = useContext(AuthContext);

  const institution = authContext.authData.institution;
  const user = authContext.authData.user;
  const [downloads, setDownloads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleteHistoryModal, setShowDeleteHistoryModal] = useState(false);
  const [downloadToDelete, setDownloadToDelete] = useState();

  useEffect(() => {
    getDownloads(authContext.authData.customer_access_token, institution.name, user.id, 1).then((response) => {
      setDownloads(response.data.downloads);
      setLoading(false);
    });
  }, []);

  const deleteDownload = (download) => {
    setDownloadToDelete(download);
    setShowDeleteHistoryModal(true);
  }

  const onDeleteDownload = (id) => {
    setDownloads(downloads.filter(d => d.id !== id));
    setShowDeleteHistoryModal(false);
  }

  return (
    <MainLayout loading={loading} currentPageKey="Historial" currentPageName="Historial" >
      <div className="py-8">
        {(downloads.length === 0) ? <NoContent>
          En esta sección se almacenarán todos los libros que has tomado en préstamo y han expirado.
          <Button variant="contained" fullWidth href="/catalogo">Ver catálogo</Button>
        </NoContent> :
          <div>
            <DeleteHistoryModal show={showDeleteHistoryModal} download={downloadToDelete} onClose={() => setShowDeleteHistoryModal(false)} onDelete={onDeleteDownload} />

            <div className="flex items-center">
              <div className="flex-grow">
                <h2 className="text-2xl font-bold">Historial</h2>
              </div>
            </div>
            <div className="py-4">
              <TableContainer component={Paper}>
                <Table aria-label="Historial">
                  <TableHead>
                    <TableRow>
                      <TableCell>Contenido</TableCell>
                      <TableCell align="right">Préstamo</TableCell>
                      <TableCell align="right">Devolución</TableCell>
                      <TableCell align="right">Cantidad de días</TableCell>
                      <TableCell align="right">Acción</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {downloads.map((download) => (

                      <TableRow
                        key={download.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <strong>{download.book.title}</strong><br />
                          {download.book.contributors.length > 0 && <span>Por {download.book.contributors[0].name}</span>}
                        </TableCell>
                        <TableCell align="right">{moment(download.created).format("DD/MM/yyyy")}</TableCell>
                        <TableCell align="right">{moment(download.returned).format("DD/MM/yyyy")}</TableCell>
                        <TableCell align="right">{moment(download.returned).diff(moment(download.created), 'days')}</TableCell>
                        <TableCell align="right">
                          <IconButton aria-label="Eliminar" onClick={() => deleteDownload(download)}><DeleteIcon /></IconButton>
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>}
      </div>
    </MainLayout>
  );
}

export default History;