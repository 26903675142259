import moment from "moment";
import { useState } from "react";
import Book from "./Book";
import PersonIcon from '@mui/icons-material/Person';
import CancelReservationModal from "./modals/CancelReservationModal";

const Reservation = ({ reservation, onCancel }) => {
  const [showCancelReservationModal, setShowCancelReservationModal] = useState(false);

  return <>
    <CancelReservationModal show={showCancelReservationModal} reservation_id={reservation.id} onCancel={onCancel} onClose={() => setShowCancelReservationModal(false)} />
    <Book
      book={reservation.book}
      menu={[{ id: reservation.id, name: "Eliminar", onClick: () => setShowCancelReservationModal(true) }]}
      cucarda={(reservation.queue_pos === 0) ? "Eres el próximo" : <>Sólo<br />{`faltan ${reservation.queue_pos}`}<PersonIcon /></>}
      showCategories={false}
    >
      <div className="text-xs">
        Reserva: {moment(reservation.created).format("DD/MM/yyyy")}
      </div>
    </Book>
  </>;
};

export default Reservation;