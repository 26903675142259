import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { getInstitution } from "../services/bidiService";


const PublicAccess = () => {
  const { institution_name } = useParams();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.loading) return;

    let authData = authContext.authData;
    const institution = authData.institution;

    if (!institution || institution_name !== institution.name) {
      window.localStorage.clear();
      authData = {};
    }

    getInstitution(institution_name).then((data) => authContext.login({ ...authData, institution: data }));
  }, [authContext]);

  return null;
}

export default PublicAccess;
