import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


const CreateLoan = ({ isLoan, handleSubmit, onClose, titleBook, LoanTime }) => {

    return (
        <div>
            <Modal
                open={true}
                onClose={onClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    {isLoan ? <Typography id="modal-modal-title" variant="h6" component="h2">
                        Estás seguro de que deseas tomar prestado el libro <b>"{titleBook}"</b> por <b>{LoanTime}</b> {LoanTime == 1 ? "día" : "días"}?
                    </Typography> :
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Estás seguro de que deseas asociar el libro <b>"{titleBook}"</b> a tu cuenta ?
                        </Typography>
                    }

                    <div className="flex justify-center gap-2 pt-4">
                        <Button fullWidth variant="outlined" onClick={() => onClose()}>No, gracias</Button>
                        <Button fullWidth variant="contained" onClick={handleSubmit}>Sí, continuar </Button>
                    </div>
                </Box>

            </Modal>
        </div>
    );
}
export default CreateLoan;