import { useState, useEffect, useContext, useCallback } from "react";
import PreloginLayout from "../layouts/PreloginLayout";
import { getInstitutions, login } from "../services/bidiService";
import { TextField, Button, Stack } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import SimpleModal from '../components/modals/SimpleModal';
import PasswordField from "../components/PasswordField";
import Cookies from 'js-cookie';


const errors = [
  "Se ha detectado que tu cuenta no se encuentra habilitada. Comunicate a la brevedad con tu Administrador.",
  "No existe la institución",
  "Revisa los datos ingresados de usuario/contraseña",
  "Hemos detectado que tu cuenta no se encuentra activa. Te enviaremos un email para activarla. En caso de no recibirlo revisa tu carpeta de spam."
];

const messages = [
  "Tu cuenta fue activada correctamente, ya puedes ingresar",
  "Cambiaste tu contraseña con éxito, ya puedes ingresar",
  "Te registraste correctamente, recibirás un email para confirmar tu cuenta.",
  "Te registraste correctamente."
];

function Login() {
  const authContext = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [institution, setInstitution] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalRedirect, setModalRedirect] = useState("");

  const { institution_name } = useParams();
  const { login_method = "native" } = useParams();
  const code = searchParams.get("code");
  const redirect_url = searchParams.get("r");

  if (redirect_url) {
    const inTwentyFiveMinutes = new Date(new Date().getTime() + 25 * 60 * 1000);
    Cookies.set('redirect_url', redirect_url, { expires: inTwentyFiveMinutes });
  }

  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (authContext.loading || !firstTime) return null;

    setFirstTime(false);

    if (authContext.authData.user && authContext.authData.institution?.name === institution_name) {
      window.location = redirect_url || "/";
    }
  }, [authContext]);


  useEffect(() => {
    getInstitutions(institution_name).then((response) => {
      const institution = response.data.find(i => i.name === institution_name);

      if (!institution) {
        window.location = '/notfound';
        return;
      }

      if (!institution.supports_native_login && login_method === 'native') {
        if (institution.login_methods.login_url) {
          window.location = institution.login_methods.login_url;
          return;
        } else {
          setModalRedirect(true);
          setModalMessage('Para iniciar sesión debes ingresar mediante tu institución.');
        }
      }

      if (!institution.show_in_front) {
        setModalRedirect(true);
        setModalMessage('Esta biblioteca se encuentra deshabilitada.');
      }

      setInstitution(institution);
      setLoading(false);
    });
  }, [institution_name]);


  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);
    setSearchParams('');

    login(event.target.username.value, event.target.password.value, institution_name, login_method).then((response) => {
      authContext.login(response.data);
    }).catch(error => {
      const error_code = error.response.data.errors[0].code;
      const error_message = errors[error_code];
      if (error_code === 3) {
        setModalMessage(error_message);
      } else {
        setError(error_message);
      }

      setLoading(false);
    });
  };



  const onCloseModal = useCallback((event) => {
    if (modalRedirect) {
      window.location = "/institutions";
      return;
    }
    setModalMessage("")
  });

  return (
    <PreloginLayout loading={loading}>
      <SimpleModal show={modalMessage} onClose={onCloseModal} title={modalMessage} description=""></SimpleModal>

      <div className="p-2 text-left max-w-min">
        <div className="flex justify-center">
          <img className="h-16 mb-6" alt={`Logo de la biblioteca ${institution.name}`} title="Logo de la biblioteca" src={institution.logo} />
        </div>

        <div className="text-sm mb-4" dangerouslySetInnerHTML={{ __html: institution.native_login_config?.login_welcome_text }}></div>

        <div className="text-sm text-green-500 mb-4">{messages[code]}</div>
        <div className="text-sm text-red-600 mb-4">{error}</div>

        <Stack spacing={3} component="form" onSubmit={handleSubmit}>
          <TextField id="standard-basic" label={institution.external_id_login_description} name="username" required variant="standard" />
          <PasswordField id="password" label="Contraseña" name="password" />
          {(institution.supports_native_login && login_method === 'native') && !institution.disable_recover_password && <div className="text-right text-[#0969da]">
            <a href={`/account/${institution.name}/forget`} title="Ir a recupera tu contraseña">Recupera tu contraseña</a>
          </div>}

          <Stack spacing={2} direction="row">
            <div className="min-w-[8.5rem]">
              {(institution.supports_native_register && login_method === 'native') &&
                <Button variant="outlined" href={`/account/${institution.name}/registro`}>Registrarse</Button>
              }
            </div>
            <Button variant="contained" type="submit">Ingresar</Button>
          </Stack>
        </Stack>


      </div>
    </PreloginLayout >
  );
}

export default Login;
